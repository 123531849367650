import {
    Box,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";

export default function OptionDrawerSelect({
    items,
    label,
    onChange,
}: {
    items: { label: string; value: string }[];
    label: string;
    onChange?: (value: string) => void;
}) {
    const [value, setValue] = useState<string>(items[0].value);
    const handleChange = (event: SelectChangeEvent) => {
        onChange?.(event.target.value);
        setValue(event.target.value);
    };
    return (
        <>
            <Box sx={{ marginLeft: 1 }}>
                <strong>{label}</strong>
            </Box>
            <FormControl
                size="small"
                sx={{ m: 1, minWidth: "10em" }}
                variant="outlined"
            >
                <Select
                    labelId={`${label}-label`}
                    onChange={handleChange}
                    value={value}
                >
                    {items.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}
