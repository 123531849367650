import {
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid2,
    List,
    ListItem,
    ListItemIcon,
    Stack,
    Typography,
} from "@mui/material";
// import { styled } from "@mui/material/styles";
// import { PieChart } from "@mui/x-charts";
// import { useDrawingArea } from "@mui/x-charts/hooks";
import { Icon, IconName } from "@theme/index";
import { useTranslation } from "react-i18next";

export default function BTWWidget() {
    const { t } = useTranslation("widgets");
    return (
        <Card
            elevation={3}
            sx={(theme) => ({
                backgroundColor:
                    theme.palette.mode === "dark"
                        ? theme.palette.background.paper
                        : theme.palette.background.default,
            })}
            variant="elevation"
        >
            <CardHeader
                title={t("BTW.title")}
                titleTypographyProps={{ fontSize: "16px", fontWeight: "400" }}
            />
            <CardContent>
                <Grid2 container>
                    <Grid2>
                        <BTWChart />
                    </Grid2>
                    <Grid2>
                        <Legend />
                    </Grid2>
                    <Grid2>
                        <BTWStatusInformation />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    );
}

function BTWChart() {
    // const { t } = useTranslation("widgets");
    // const data = [
    //     {
    //         color: "#eaa1ac",
    //         id: 0,
    //         label: "Deadline verlopen",
    //         value: 5,
    //     },
    //     {
    //         color: "#f2c480",
    //         id: 1,
    //         label: "Niet ingediend, deadline aanstaande",
    //         value: 20,
    //     },
    //     {
    //         color: "#9bc29b",
    //         id: 2,
    //         label: "Ingediend en akkoord",
    //         value: 75,
    //     },
    // ];
    return (
        <></>
        // <PieChart
        //     height={200}
        //     margin={{ right: 0 }}
        //     series={[
        //         {
        //             cornerRadius: 5,
        //             data: data,
        //             innerRadius: 50,
        //             outerRadius: 100,
        //             paddingAngle: 1,
        //         },
        //     ]}
        //     slotProps={{
        //         legend: {
        //             sx: {
        //                 display: "none",
        //             },
        //         },
        //     }}
        //     width={245}
        // >
        //     <PieCenterLabel current={3} label={t("BTW.turnedIn")} total={10} />
        // </PieChart>
    );
}

function BTWStatusInformation() {
    const { t } = useTranslation("widgets");
    type CompanyStatus = {
        icon: IconName;
        name: string;
        status: {
            label: string;
            type: "error" | "info" | "warning";
            value?: number;
        }[];
    };
    const data: CompanyStatus[] = [
        {
            icon: "ImportCompany",
            name: "Administratienaam 1",
            status: [{ label: t("BTW.daysPast"), type: "error", value: 5 }],
        },
        {
            icon: "Office",
            name: "Administratienaam 2",
            status: [{ label: t("BTW.daysToGo"), type: "warning", value: 3 }],
        },
        {
            icon: "Housing",
            name: "Administratienaam 3",
            status: [
                { label: t("BTW.daysToGo"), type: "warning", value: 3 },
                { label: t("BTW.toFiat"), type: "info" },
            ],
        },
    ];
    return (
        <List
            sx={{
                maxHeight: "270px",
                overflowY: "scroll",
                padding: 0,
            }}
        >
            {data.map((company, index) => {
                const isLast = index === data.length - 1;
                return (
                    <CompanyListItem
                        icon={company.icon}
                        isLast={isLast}
                        key={company.name}
                        name={company.name}
                        status={company.status}
                    />
                );
            })}
        </List>
    );
}

function CompanyListItem({
    icon,
    isLast,
    name,
    status,
}: {
    icon: IconName;
    isLast: boolean;
    name: string;
    status: {
        label: string;
        type: "error" | "info" | "warning";
        value?: number;
    }[];
}) {
    return (
        <ListItem>
            <Stack gap={1} width={"100%"}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" gap={1}>
                        <Icon name={icon} size="sm" />
                        <Typography variant="body2">{name}</Typography>
                    </Stack>
                    <Icon name="ChevronRight" size="sm" />
                </Stack>
                <Stack direction="row" gap={1}>
                    {status.map((status) => {
                        let icon: IconName;
                        if (status.type === "error") {
                            icon = "Absence2";
                        } else if (status.type === "info") {
                            icon = "Info";
                        } else {
                            icon = "UndefinedDocument";
                        }
                        return (
                            <Chip
                                color={status.type}
                                icon={<Icon name={icon} size="sm" />}
                                key={status.label}
                                label={`${status.value ?? ""} ${status.label}`}
                                sx={{
                                    "& .MuiChip-label": {
                                        paddingLeft: "0.6em",
                                    },
                                    "& svg": { paddingLeft: "8px" },
                                    height: "30px",
                                }}
                                variant="filled"
                            />
                        );
                    })}
                </Stack>

                {!isLast && <Divider sx={{ borderStyle: "dashed" }} />}
            </Stack>
        </ListItem>
    );
}

function Legend() {
    const { t } = useTranslation();
    return (
        <List dense sx={{ marginTop: "1em" }}>
            <ListItem>
                <LegendChip variant="error" />
                <Stack>
                    <Typography variant="caption">
                        1 {t("general:company")}
                    </Typography>
                    <Typography variant="body2">
                        {t("widgets:BTW.deadline")}
                    </Typography>
                </Stack>
            </ListItem>
            <ListItem>
                <LegendChip variant="warning" />
                <Stack>
                    <Typography variant="caption">
                        5 {t("general:companies")}
                    </Typography>
                    <Typography variant="body2">
                        {t("widgets:BTW.notTurnedIn")}
                    </Typography>
                </Stack>
            </ListItem>
            <ListItem>
                <LegendChip variant="success" />
                <Stack>
                    <Typography variant="caption">
                        20 {t("general:companies")}
                    </Typography>
                    <Typography variant="body2">
                        {t("widgets:BTW.turnedIn")}
                    </Typography>
                </Stack>
            </ListItem>
        </List>
    );
}

function LegendChip({
    variant,
}: {
    variant?: "error" | "success" | "warning";
}) {
    return (
        <ListItemIcon sx={{ marginRight: "0.3em", minWidth: "10px" }}>
            <Chip
                color={variant}
                size="small"
                sx={{ height: "36px", width: "8px" }}
            />
        </ListItemIcon>
    );
}
// function PieCenterLabel({
//     current,
//     label,
//     total,
// }: {
//     current: number;
//     label: string;
//     total: number;
// }) {
//     const StyledText = styled("text")(({ theme }) => ({
//         dominantBaseline: "central",
//         fill: theme.palette.text.primary,
//         fontSize: 16,
//         textAnchor: "middle",
//     }));

//     const { height, left, top, width } = useDrawingArea();
//     return (
//         <>
//             <StyledText x={left + width / 2} y={top + (height - 25) / 2}>
//                 {label}
//             </StyledText>
//             <StyledText x={left + width / 2} y={top + (height + 25) / 2}>
//                 {current}/{total}
//             </StyledText>
//         </>
//     );
// }
