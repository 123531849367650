import { Card, CardContent, CardHeader, Grid2 } from "@mui/material";
import { red, yellow } from "@mui/material/colors";
import { ErrorComponentProps } from "@tanstack/react-router";

export default function DefaultError({
    error,
}: {
    error: ErrorComponentProps;
}) {
    const { message, name, stack } = error.error;
    return (
        <Grid2 container>
            <Grid2 offset={3} size={6}>
                <Card
                    sx={{
                        alignSelf: "center",
                        backgroundColor: red[200],
                    }}
                    variant="elevation"
                >
                    <CardHeader title={name} />
                    <CardContent>
                        <p>{message}</p>
                        <p
                            style={{
                                backgroundColor: yellow[50],
                                border: `1px solid ${yellow[200]}`,
                                padding: "1em",
                            }}
                        >
                            <code>{stack}</code>
                        </p>
                    </CardContent>
                </Card>
            </Grid2>
        </Grid2>
    );
}
