import { createRoute } from "@tanstack/react-router";

import { OverviewPage } from "../pages/overview-page";
import { cardLayoutRoute } from "./layouts";

const overviewRoute = createRoute({
    component: OverviewPage,
    getParentRoute: () => cardLayoutRoute,
    path: "/overview/$provider",
});

export default overviewRoute;
