import { createRoute } from "@tanstack/react-router";

import UserOverviewPage from "../pages/user-overview-page";
import { cardLayoutRoute } from "./layouts";

const userOverviewRoute = createRoute({
    component: UserOverviewPage,
    getParentRoute: () => cardLayoutRoute,
    path: "/user/overview",
});

export default userOverviewRoute;
