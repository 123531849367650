import type { ListItemButtonProps } from "@mui/material/ListItemButton";

import { IconName } from "@/theme";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
    Box,
    Collapse,
    List,
    ListItemButton,
    Stack,
    Typography,
} from "@mui/material";
import { createLink } from "@tanstack/react-router";
import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "../../theme/icon";
import { FlattenedMenuItem } from "./subnavigation";

type MegaMenuColumnProps = {
    column: {
        icon?: IconName;
        items: FlattenedMenuItem[];
        title?: string;
    };
    onItemClick: (link: null | string) => void;
};

export function MegaMenuColumn({ column, onItemClick }: MegaMenuColumnProps) {
    return (
        <Box sx={{ width: "325px" }}>
            {column.title && (
                <Typography
                    color={"primary"}
                    flexShrink={0}
                    fontSize={"16px"}
                    fontWeight={"bold"}
                    sx={{
                        borderBottom: "1px solid #dadada",
                        marginLeft: "10px",
                        marginRight: "25px",
                        marginTop: "10px",
                        paddingBottom: "0.1em",
                    }}
                    variant="h6"
                >
                    <Stack direction="row" height={"24px"} spacing={1}>
                        {column.icon && (
                            <Box sx={{ paddingTop: "3px" }}>
                                <Icon name={column.icon} size="xs" />
                            </Box>
                        )}
                        <span>{column.title}</span>
                    </Stack>
                </Typography>
            )}
            <List sx={{ paddingRight: "2em" }}>
                {column.items.map((item) => (
                    <Item item={item} key={item.id} onItemClick={onItemClick} />
                ))}
            </List>
        </Box>
    );
}

function Item({
    item,
    onItemClick,
}: {
    item: FlattenedMenuItem;
    onItemClick: (link: null | string) => void;
}) {
    const ListItemButtonLinkComponent = forwardRef<
        HTMLAnchorElement,
        ListItemButtonProps
    >((props, ref) => {
        return <ListItemButton component={"a"} ref={ref} {...props} />;
    });
    const ListItemButtonLink = createLink(ListItemButtonLinkComponent);
    const [open, setOpen] = useState<{ [key: string]: boolean }>({});
    const { t } = useTranslation("root");

    const handleClick = (label: string) => {
        setOpen((previousOpen) => ({
            ...previousOpen,
            [label]: !previousOpen[label],
        }));
    };

    const hasChildren = item.children && item.children.length > 0;
    const isOpen = open[item.label];

    return (
        <div>
            <ListItemButton
                component={item.link ? ListItemButtonLink : "div"}
                onClick={() => {
                    if (hasChildren) {
                        handleClick(item.label);
                    } else if (item.link) {
                        onItemClick(item.link);
                    }
                }}
                sx={{
                    backgroundColor:
                        hasChildren && isOpen ? "action.hover" : "inherit",
                }}
                to={item.link ? "/" + item.link : undefined}
            >
                <Typography color="textPrimary" variant="body2">
                    {item.label && t(`mega-menu.${item.label}`, item.label)}
                </Typography>
                {hasChildren && (isOpen ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            {hasChildren && (
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {item.children.map((child, childIndex) => (
                            <ListItemButtonLink
                                key={childIndex}
                                onClick={() => {
                                    onItemClick(child.link);
                                }}
                                sx={{ marginLeft: 2 }}
                                to={"/" + child.link || undefined}
                            >
                                <Typography color="textPrimary" variant="body2">
                                    {child.label &&
                                        t(
                                            `mega-menu.${child.label}`,
                                            child.label,
                                        )}
                                </Typography>
                            </ListItemButtonLink>
                        ))}
                    </List>
                </Collapse>
            )}
        </div>
    );
}
