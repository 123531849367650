import { Icon } from "@/theme";
import { Button, ButtonProps } from "@mui/material";
import { useTranslation } from "react-i18next";

export function CancelButton(props: ButtonProps) {
    const { t } = useTranslation("general");
    return (
        <Button
            color="info"
            variant="outlined"
            {...props}
            startIcon={<Icon name="CloseX" size="sm" />}
        >
            {t("cancel")}
        </Button>
    );
}

export function SaveButton(props: ButtonProps) {
    const { t } = useTranslation("general");
    return (
        <Button
            color="success"
            startIcon={<Icon name="Save" size="sm" />}
            type="submit"
            variant="contained"
            {...props}
        >
            {t("save")}
        </Button>
    );
}
