import type { components } from "commonapi.openapi";

import { DataGridToolbar } from "@/components/datagrid/datagrid-toolbar";
import { Box, Grid2 } from "@mui/material";
import {
    DataGrid,
    DataGridProps,
    GridColDef,
    GridPaginationMeta,
    GridPaginationModel,
    GridRowParams,
    GridValidRowModel,
    useGridApiRef,
} from "@mui/x-data-grid";
import { useNavigate } from "@tanstack/react-router";
import { type Dispatch, type SetStateAction, useMemo, useRef } from "react";

export function OverviewDataGrid<T extends GridValidRowModel>({
    columns,
    data,
    paginationModel,
    provider,
    rowID,
    setPaginationModel,
    title,
}: {
    columns: GridColDef<T>[];
    data: {
        items: T[];
        links: components["schemas"]["LinksResponse"];
        meta: components["schemas"]["MetaResponse"];
    };
    paginationModel: GridPaginationModel;
    provider: string;
    rowID: keyof T | string;
    setPaginationModel: Dispatch<SetStateAction<GridPaginationModel>>;
    title: string;
}) {
    const hasNextPage = !!data.links.next;

    const apiRef = useGridApiRef();
    const clearFilters = () => apiRef.current.setFilterModel({ items: [] });
    const navigate = useNavigate({ from: "/overview/$provider" });

    const handlePaginationChange = (
        newPaginationModel: GridPaginationModel,
    ) => {
        if (newPaginationModel.page > paginationModel.page && !hasNextPage) {
            return;
        }
        setPaginationModel(newPaginationModel);
    };

    const defaultDataGridProps: Partial<DataGridProps> = {
        disableRowSelectionOnClick: true,
        pageSizeOptions: [5, 10, 25, 50, 100],
        paginationMode: "server",
        rowCount: -1,
        slotProps: {
            toolbar: {
                quickFilterProps: { variant: "outlined" },
            },
        },
        slots: {
            toolbar: (props) =>
                DataGridToolbar({
                    clearFilters: clearFilters,
                    title: title,
                    ...props,
                }),
        },
    };
    const paginationMetaRef = useRef<GridPaginationMeta>(undefined);
    const paginationMeta = useMemo(() => {
        if (
            hasNextPage !== undefined &&
            paginationMetaRef.current?.hasNextPage !== hasNextPage
        ) {
            paginationMetaRef.current = { hasNextPage };
        }
        return paginationMetaRef.current;
    }, [hasNextPage]);

    return (
        <Grid2 container m={4}>
            <Box
                sx={{
                    maxHeight: 750,
                    minHeight: 400,
                    overflow: "auto",
                    width: "100%",
                }}
            >
                <DataGrid
                    apiRef={apiRef}
                    columns={columns}
                    editMode="row"
                    getRowId={(row: T) => {
                        return row[rowID];
                    }}
                    initialState={{
                        pagination: {
                            meta: {
                                hasNextPage: hasNextPage,
                            },
                            paginationModel: paginationModel,
                            rowCount: -1,
                        },
                    }}
                    onPaginationModelChange={handlePaginationChange}
                    onRowClick={async (params: GridRowParams<T>) => {
                        await navigate({
                            params: {
                                editId: `${params.id}`,
                                provider: provider,
                            },
                            to: "/edit/$provider/$editId",
                        });
                    }}
                    paginationMeta={paginationMeta}
                    paginationModel={paginationModel}
                    rows={data.items}
                    sx={{
                        "& .MuiDataGrid-columnHeaders": {
                            fontSize: "16px",
                            fontWeight: 500,
                        },
                        "& .MuiTablePagination-displayedRows": {
                            display: "none",
                        },
                    }}
                    {...defaultDataGridProps}
                />
            </Box>
        </Grid2>
    );
}
