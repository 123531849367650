import type { RJSFSchema } from "@rjsf/utils";
export const balanceCodesFormSchema: RJSFSchema = {
    properties: {
        balance_code: {
            maxLength: 10,
            required: ["true"],
            title: "Balance Code",
            type: "string",
        },
        description: {
            required: ["true"],
            title: "Description",
            type: "string",
        },
    },
    required: ["balance_code", "description"],
    title: "Balance Code",
    type: "object",
};
