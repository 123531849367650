import { Icon, IconName } from "@/theme";
import {
    Drawer,
    DrawerProps,
    Grid2,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useToggleDrawer } from "../../hooks/use-toggle-drawer";

export function OptionDrawer({
    children,
    description,
    icon,
    title,
    ...props
}: DrawerProps & {
    children?: React.ReactNode;
    description?: string;
    icon?: IconName;
    title?: string;
}) {
    const { t } = useTranslation("balance");
    const toggleDrawer = useToggleDrawer();
    return (
        <Drawer
            anchor="right"
            onClose={() => {
                toggleDrawer();
                return false;
            }}
            sx={{
                "& .MuiDrawer-paper": {
                    borderLeft: "5px solid",
                    borderLeftColor: "#1482CC !important",
                    maxWidth: "40em",
                    overflowY: "scroll",
                    padding: "1em",
                },
            }}
            {...props}
        >
            <Grid2 container>
                <Grid2 size={12}>
                    <Stack
                        direction="row"
                        gap={2}
                        justifyContent={"space-between"}
                    >
                        <Stack direction="row" gap={2}>
                            {icon && <Icon name={icon} size="sm" />}
                            {title && (
                                <Typography variant="h5">{t(title)}</Typography>
                            )}
                        </Stack>
                        <IconButton
                            aria-label="close"
                            onClick={() => toggleDrawer()}
                            role="close"
                        >
                            <Icon name="CloseX" size="sm" />
                        </IconButton>
                    </Stack>
                    {description && <p>{t(description)}</p>}
                </Grid2>
                <Grid2
                    alignItems="stretch"
                    display="flex"
                    justifyContent="space-between"
                    size={12}
                >
                    {children}
                </Grid2>
            </Grid2>
        </Drawer>
    );
}

export function OptionDrawerActions({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <Grid2
            alignContent="end"
            display="flex"
            gridColumn={12}
            justifyContent="end"
            size="grow"
            sx={{
                borderTop: "1px solid #E0E0E0",
                paddingTop: 2,
            }}
        >
            <Stack direction="row" gap={1}>
                {children}
            </Stack>
        </Grid2>
    );
}

export function OptionDrawerOptionGroup({
    children,
    last,
    title,
}: {
    children: React.ReactNode;
    last?: boolean;
    title: string;
}) {
    if (last) {
        return (
            <Grid2 size={12}>
                <Typography variant="h6">{title}</Typography>
                {children}
            </Grid2>
        );
    }
    return (
        <Grid2
            paddingBottom={2}
            size={12}
            sx={{ borderBottom: "1px dashed #E0E0E0" }}
        >
            <Typography variant="h6">{title}</Typography>
            {children}
        </Grid2>
    );
}
