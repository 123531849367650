import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";

export function GridField<T extends GridValidRowModel>({
    editable = false,
    field,
    headerName,
    width = 200,
}: {
    editable?: boolean;
    field: keyof T;
    headerName: string;
    width?: number;
}): GridColDef<T> {
    return {
        editable: editable,
        field: field as string,
        headerName: headerName,
        width: width,
    };
}
