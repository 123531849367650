import type { RJSFSchema } from "@rjsf/utils";
export const jobFunctionFormSchema: RJSFSchema = {
    properties: {
        description: {
            required: ["true"],
            title: "Description",
            type: "string",
        },
        job_function: {
            maxLength: 10,
            required: ["true"],
            title: "Job Function",
            type: "string",
        },
        no_mailing: {
            title: "No Mailing",
            type: "string",
        },
        unique_presence: {
            title: "Unique Presence",
            type: "string",
        },
    },
    required: ["job_function", "description"],
    title: "Job Function",
    type: "object",
};
