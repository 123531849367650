import { GlobalStyles } from "@mui/material";
import { Outlet } from "@tanstack/react-router";
const heroBackgroundStyle = (
    <GlobalStyles
        styles={(theme) => ({
            body: {
                backgroundImage:
                    theme.palette.mode === "dark"
                        ? "url(/assets/cash-logo-bg-dark.svg) !important"
                        : "url(/assets/cash-logo-bg.svg) !important",
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                overflow: "auto",
            },
        })}
    />
);
export default function HeroLayout() {
    return (
        <>
            {heroBackgroundStyle}
            <Outlet />
        </>
    );
}
