import { commonAPI } from "@/api";
import { OverviewDataGrid } from "@/components/datagrid/overview-datagrid";
import { ErrorState, PendingState } from "@/components/general/state";
import { usePagedCommonQueryParameters } from "@/hooks/queries/use-paged-common-query-parameters";
import { components } from "commonapi.openapi";

import { JobFunctionOverviewColumns } from "./columns/job-function";

export type RepositoryType = components["schemas"]["FunctionTypeResponse"];

export default function JobFunctionOverviewProvider() {
    const rowID: keyof RepositoryType = "function_type_id";
    const provider = "function_type";
    const title = "Job Function";
    const columns = JobFunctionOverviewColumns();

    const { pagedCommonQueryParameters, paginationModel, setPaginationModel } =
        usePagedCommonQueryParameters();

    const { data, error, isError, isPending } = commonAPI.useQuery(
        "get",
        "/common-api/v1/{cash_customer}/{company_id}/function_types",
        pagedCommonQueryParameters,
    );

    const datagridProps = {
        paginationModel: paginationModel,
        provider: provider,
        rowID: rowID,
        setPaginationModel: setPaginationModel,
        title: title,
    };

    if (isPending) {
        return <PendingState />;
    }

    if (isError) {
        return <ErrorState error={error} />;
    }

    return (
        <OverviewDataGrid columns={columns} data={data} {...datagridProps} />
    );
}
