import type { GridColDef } from "@mui/x-data-grid";

import { GridField } from "@/components/datagrid/grid-field";
import { useTranslation } from "react-i18next";

import type { RepositoryType } from "../balance-codes";

export default function BalanceCodesOverviewColumns() {
    const { t } = useTranslation("form");
    const columns: GridColDef<RepositoryType>[] = [
        GridField<RepositoryType>({
            field: "balance_code",
            headerName: t("columns.balanceCode"),
        }),
        GridField<RepositoryType>({
            field: "description",
            headerName: t("columns.description"),
        }),
    ];
    return columns;
}
