import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { gridSizeAtom } from "../balance-atoms";
import { BalanceTableDirection } from "../balance-table-direction";

export default function BalanceDisplayOptions() {
    const [gridSize, setGridSize] = useAtom(gridSizeAtom);
    const { t } = useTranslation("balance");
    const handleAlignment = (
        _event: React.MouseEvent<HTMLElement>,
        newAlignment: BalanceTableDirection,
    ) => {
        setGridSize(newAlignment);
    };
    return (
        <ToggleButtonGroup
            aria-label="balance alignment"
            exclusive
            onChange={handleAlignment}
            value={gridSize}
        >
            <ToggleButton value={BalanceTableDirection.SideBySide}>
                {t("alignment.side-by-side")}
            </ToggleButton>
            <ToggleButton value={BalanceTableDirection.Stacked}>
                {t("alignment.stacked")}
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
