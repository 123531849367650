import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { Currency } from "./default";

export const companyIdAtom = atomWithStorage<string>(
    "company:id",
    "0ae67a9f-f98c-4c63-ae99-084bdab98269",
);
export const customerIdAtom = atomWithStorage<string>(
    "customer:id",
    "00000000-0000-0000-0000-000000000000",
);

export const companyNameAtom = atom<string>("Selecteer een administratie");
export const customerNameAtom = atom<string>("van een bedrijfsomgeving");

export const commonIDAtom = atom((get) => ({
    company: get(companyIdAtom),
    customer: get(customerIdAtom),
}));

export const companyAtom = atom((get) => ({
    id: get(companyIdAtom),
    name: get(companyNameAtom),
}));

export const customerAtom = atom((get) => ({
    id: get(customerIdAtom),
    name: get(customerNameAtom),
}));

export const currencyAtom = atom<Currency>("EUR");
