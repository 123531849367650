import { ProviderKeys } from "@/providers/providers";

import BalanceCodeForm from "./balance-codes";
import ContactForm from "./contact";
import CostCenterForm from "./cost-center";
import JobFunctionForm from "./job-function";
import KeywordForm from "./keyword";

export const forms = {
    balance_codes: BalanceCodeForm,
    contact: ContactForm,
    cost_center: CostCenterForm,
    cost_centres: CostCenterForm,
    function_type: JobFunctionForm,
    job_function: JobFunctionForm,
    keyword: KeywordForm,
};

export default function getForm(name: ProviderKeys) {
    return forms[name];
}
