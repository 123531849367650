import { JournalOverviewPage } from "@/pages/journal-overview-page";
import { createRoute } from "@tanstack/react-router";

import { cardLayoutRoute } from "./layouts";

const journalOverviewRoute = createRoute({
    component: JournalOverviewPage,
    getParentRoute: () => cardLayoutRoute,
    path: "/journal/overview",
});

export default journalOverviewRoute;
