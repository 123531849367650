import type { RJSFSchema } from "@rjsf/utils";

export const costCenterFormSchema: RJSFSchema = {
    properties: {
        cost_center_id: {
            title: "Cost Center ID",
            type: "string",
        },
        description: {
            title: "Description",
            type: "string",
        },
        name: {
            title: "Name",
            type: "string",
        },
    },
    required: ["cost_center_id", "name"],
    type: "object",
};
