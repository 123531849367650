import type { ProviderKeys } from "@/providers/providers";

import { ErrorState } from "@/components/general/state";
import getProvider from "@/providers/providers";
import overviewRoute from "@/routes/overview";

export function OverviewPage() {
    const { provider: providerName }: { provider: ProviderKeys } =
        overviewRoute.useParams();
    const Provider = getProvider(providerName);

    if (!Provider)
        return (
            <ErrorState
                error={{
                    detail: [
                        {
                            loc: ["provider"],
                            msg: `Provider ${providerName} not found`,
                            type: "provider.not_found",
                        },
                    ],
                }}
            />
        );

    return <Provider />;
}
