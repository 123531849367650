import { createRoute } from "@tanstack/react-router";

import { BalancePage } from "../pages/balance-page";
import { cardLayoutRoute } from "./layouts";

const balanceRoute = createRoute({
    component: BalancePage,
    getParentRoute: () => cardLayoutRoute,
    path: "/balance",
});

export default balanceRoute;
