import { useCommonPathParams } from "./use-common-path-params";
import { usePaginationModel } from "./use-pagination-model";

/**
 * Hook to get query parameters for paged common queries
 * @returns query parameters for paged financial queries and pagination model
 */
export function usePagedFinancialQueryParameters() {
    const [paginationModel, setPaginationModel] = usePaginationModel();
    const path = useCommonPathParams();

    const pagedFinancialQueryParameters = {
        params: {
            path: path,
            query: {
                page: Math.max(1, paginationModel.page + 1),
                per_page: Math.max(paginationModel.pageSize, 5),
            },
        },
    };

    return {
        pagedFinancialQueryParameters,
        paginationModel,
        setPaginationModel,
    };
}
