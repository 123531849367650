import { financialAPI } from "@/api";
import { balanceOptionsAtom } from "@/components/balance/balance-atoms";
import { components } from "financialapi.openapi";
import { useAtomValue } from "jotai";

export type Activa = {
    data: components["schemas"]["BalanceSheetResponse"]["activa_data"];
    difference: components["schemas"]["BalanceSheetResponse"]["activa_balance_difference"];
    totalAmountEndBalance: components["schemas"]["BalanceSheetResponse"]["total_amount_activa_end_balance"];
    totalAmountStartBalance: components["schemas"]["BalanceSheetResponse"]["total_amount_activa_start_balance"];
};
export type Passiva = {
    data: components["schemas"]["BalanceSheetResponse"]["passiva_data"];
    difference: components["schemas"]["BalanceSheetResponse"]["passiva_balance_difference"];
    totalAmountEndBalance: components["schemas"]["BalanceSheetResponse"]["total_amount_passiva_end_balance"];
    totalAmountStartBalance: components["schemas"]["BalanceSheetResponse"]["total_amount_passiva_start_balance"];
};

export function useBalanceDataQuery() {
    const balanceOptions = useAtomValue(balanceOptionsAtom);
    return financialAPI.useQuery(
        "get",
        "/fin-api/v1/{cash_customer}/{company_id}/balance_overview",
        {
            params: {
                path: {
                    cash_customer: balanceOptions.cash_customer,
                    company_id: balanceOptions.company_id,
                },
                query: {
                    currency: balanceOptions.currency,
                    financial_year: balanceOptions.financial_year,
                    period: balanceOptions.period,
                },
            },
        },
    );
}
