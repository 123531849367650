import { Card, CardContent, GlobalStyles, Grid2 } from "@mui/material";
import { Outlet } from "@tanstack/react-router";

const darkerBodyStyles = (
    <GlobalStyles
        styles={(theme) => ({
            body: { backgroundColor: theme.palette.background.admin },
        })}
    />
);

export default function CardLayout() {
    return (
        <>
            {darkerBodyStyles}
            <Grid2 offset={{ md: 0, sm: 1 }} size={{ md: 12, sm: 10 }}>
                <Card sx={{ m: "50px" }}>
                    <CardContent>
                        <Outlet />
                    </CardContent>
                </Card>
            </Grid2>
        </>
    );
}
