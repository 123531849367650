import { Card, CardContent, Grid2 } from "@mui/material";
import { useAtomValue } from "jotai";

import { OptionDrawer } from "../general/option-drawer";
import { balanceDrawerAtom, gridSizeAtom } from "./balance-atoms";
import { BalanceTableToolbar } from "./balance-toolbar";

export default function BalanceTableLayout({
    activaTable,
    optionDrawer,
    passivaTable,
}: {
    activaTable: React.ReactNode;

    optionDrawer: React.ReactNode;
    passivaTable: React.ReactNode;
}) {
    const open = useAtomValue(balanceDrawerAtom);
    const gridSize = useAtomValue(gridSizeAtom);
    return (
        <>
            <Card sx={{ margin: "0 auto", width: "90%" }}>
                <CardContent>
                    <Grid2 container justifyContent={"space-between"}>
                        <Grid2 paddingY={"1em"} size={12}>
                            <BalanceTableToolbar />
                        </Grid2>
                        <Grid2
                            data-testid="tableGrid"
                            minHeight={"25em"}
                            paddingY={"1em"}
                            size={gridSize}
                        >
                            {activaTable}
                        </Grid2>
                        <Grid2
                            minHeight={"25em"}
                            paddingY={"1em"}
                            size={gridSize}
                        >
                            {passivaTable}
                        </Grid2>
                    </Grid2>
                </CardContent>
            </Card>
            <OptionDrawer
                description={"options.adjustBalanceMessage"}
                icon="ControlPanel"
                open={open}
                title={"options.adjustBalance"}
            >
                {optionDrawer}
            </OptionDrawer>
        </>
    );
}
