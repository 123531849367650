import { OptionDrawer } from "@/components/general/option-drawer";
import OptionDrawerContent from "@/components/user-overview/option-drawer-content";
import UserOverviewDataGrid from "@/components/user-overview/user-overview-datagrid";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Icon } from "@theme/index";
import { useState } from "react";

const initialRows = [
    {
        id: 1,
        profile: "Ux Designer",
        type: "Light",
        username: "Daniel Salomon",
    },
    { id: 2, profile: "Profiel B", type: "Full", username: "John Dijns" },
    { id: 3, profile: "Profiel C", type: "Full", username: "Joost van Duren" },
    {
        id: 4,
        profile: "Geen profiel",
        type: "Light",
        username: "Rodi Cornelisse",
    },
];

const UserOverviewPage = () => {
    const [rows, setRows] = useState(initialRows);
    const [hoveredRow, setHoveredRow] = useState<null | number>(null);
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [showInfoIcon, setShowInfoIcon] = useState<null | number>(null);

    const handleToggleDrawer = () => setOpen(!open);

    const handleProfileChange = (
        event: SelectChangeEvent<string>,
        parameters: GridRenderCellParams,
    ) => {
        setRows(
            rows.map((row) =>
                row.id === parameters.id
                    ? { ...row, profile: event.target.value }
                    : row,
            ),
        );
        setIsEditMode(true);
        setShowInfoIcon(parameters.id as number);
    };

    const handleHerstellen = () => {
        setIsEditMode(false);
        setShowInfoIcon(null);
        setHoveredRow(null);
    };

    const handleOpslaan = () => {
        setIsEditMode(false);
        setShowInfoIcon(null);
        setHoveredRow(null);
    };

    return (
        <Box
            sx={(theme) => ({
                backgroundColor: theme.palette.background.admin,
                backgroundSize: "cover",
                height: "calc(100vh - 64px - 48px - 10px)",
                overflow: "auto",
                position: "relative",
                width: "100%",
            })}
        >
            <Card
                sx={(theme) => ({
                    alignItems: "flex-start",
                    backgroundColor: theme.palette.background.container,
                    borderRadius: "8px",
                    boxShadow: "0px 1px 1px 0px rgba(22,62,89,0.10)",
                    margin: "32px 75px",
                    maxWidth: "100%",
                    position: "relative",
                })}
            >
                <CardHeader
                    title="Overzicht van je gebruikers"
                    titleTypographyProps={{
                        fontFamily: "Ubuntu",
                        fontWeight: 500,
                        marginLeft: "32px",
                        marginTop: "24px",
                    }}
                />
                <CardContent sx={{ margin: "0 32px" }}>
                    <UserOverviewDataGrid
                        handleProfileChange={handleProfileChange}
                        handleToggleDrawer={handleToggleDrawer}
                        hoveredRow={hoveredRow}
                        isEditMode={isEditMode}
                        rows={rows}
                        setHoveredRow={setHoveredRow}
                        showInfoIcon={showInfoIcon}
                    />
                    <div
                        style={{
                            borderBottom: "2px solid #DADADA",
                            marginTop: "16px",
                            minHeight: "200px",
                        }}
                    ></div>
                    <Box
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "16px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Open Sans",
                                fontSize: "14px",
                                fontStyle: "italic",
                                fontWeight: 400,
                            }}
                        >
                            {rows.length} gebruikers
                        </Typography>
                        {isEditMode ? (
                            <Box
                                sx={{
                                    alignItems: "center",
                                    display: "flex",
                                    gap: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        fontFamily: "Open Sans",
                                        fontSize: "14px",
                                        fontStyle: "italic",
                                        fontWeight: 400,
                                    }}
                                >
                                    <Icon
                                        color="#1482CC"
                                        name="InfoFill"
                                        size="xs"
                                    />
                                    <div style={{ marginLeft: "15px" }}>
                                        Het profiel van 1 gebruiker is
                                        gewijzigd!
                                    </div>
                                </Typography>

                                <Button
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleHerstellen();
                                    }}
                                    sx={{
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #8A8A8A",
                                        color: "#252626",
                                    }}
                                    variant="outlined"
                                >
                                    <Icon name="Refresh" size="xs" />
                                    <div style={{ marginLeft: 8 }}>
                                        Herstellen
                                    </div>
                                </Button>
                                <Button
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleOpslaan();
                                    }}
                                    sx={{
                                        backgroundColor: "#2D7048",
                                        color: "#FFFFFF",
                                    }}
                                    variant="contained"
                                >
                                    <Icon name="Save" size="xs" />
                                    <div style={{ marginLeft: "8px" }}>
                                        Wijzigingen opslaan
                                    </div>
                                </Button>
                            </Box>
                        ) : (
                            <Button
                                color="info"
                                onClick={(event) => event.stopPropagation()}
                                variant="outlined"
                            >
                                <Icon name="EditFile" size="xs" />
                                <div style={{ marginLeft: 8 }}>
                                    Profielen beheren
                                </div>
                            </Button>
                        )}
                    </Box>
                </CardContent>
                <OptionDrawer children={<OptionDrawerContent />} open={open} />
            </Card>
        </Box>
    );
};

export default UserOverviewPage;
