import { CircularProgress, Grid2 } from "@mui/material";
import { components } from "commonapi.openapi";

import { ErrorCard } from "./cards";

export function ErrorState({
    error,
}: {
    error: null | {
        detail?: components["schemas"]["ValidationError"][];
    };
}) {
    {
        return (
            <Grid2 container m={4} sx={{ height: "65vh" }}>
                <ErrorCard error={error || "Error"} />
            </Grid2>
        );
    }
}

export function PendingState() {
    return (
        <Grid2 container m={4} sx={{ height: "65vh" }}>
            <CircularProgress />
        </Grid2>
    );
}
