import { Icon } from "@/theme";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";

import { balanceDrawerAtom, balanceOptionsAtom } from "./balance-atoms";

export function BalanceTableToolbar() {
    const { t } = useTranslation("balance");
    const [open, setOpen] = useAtom(balanceDrawerAtom);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const { financial_year: year } = useAtomValue(balanceOptionsAtom);
    return (
        <Stack direction="row" gap={2} justifyContent="space-between">
            <Stack direction="row" gap={2}>
                <Typography sx={{ fontWeight: 500 }} variant="h5">
                    {t("balance")}
                </Typography>
                <Typography sx={{ fontWeight: 400 }} variant="h5">
                    {t("finalBalance")} {year}
                </Typography>
            </Stack>
            <Stack direction="row" gap={1}>
                <Button
                    color="info"
                    onClick={() => toggleDrawer()}
                    startIcon={<Icon name="ControlPanel" size="sm" />}
                >
                    {t("options.adjustBalance")}
                </Button>
                <IconButton aria-label="print">
                    <Icon name="Print" size="sm" />
                </IconButton>
                <IconButton aria-label="download">
                    <Icon name="Download1" size="sm" />
                </IconButton>
                <IconButton aria-label="close">
                    <Icon name="CloseX" size="sm" />
                </IconButton>
                <Button />
            </Stack>
        </Stack>
    );
}
