import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { components } from "commonapi.openapi";

export function ErrorCard({
    error,
}: {
    error:
        | null
        | string
        | {
              detail?: components["schemas"]["ValidationError"][];
          };
}) {
    if (!error) {
        return (
            <Card variant="outlined">
                <CardHeader title="Error" />
                <CardContent>
                    {error === null && (
                        <Typography color="error" variant="h6">
                            An unknown error occurred.
                        </Typography>
                    )}
                </CardContent>
            </Card>
        );
    }
    return (
        <Card variant="outlined">
            <CardHeader title="Error" />
            <CardContent>
                {typeof error === "string" && (
                    <Typography color="error" variant="h6">
                        {error}
                    </Typography>
                )}
                {typeof error !== "string" && (
                    <>
                        <Typography color="error" variant="h6">
                            {error.detail
                                ?.map((detail) => detail.type)
                                .join(", ")}
                        </Typography>
                        <Typography variant="caption">
                            {error.detail
                                ?.map((detail) => detail.msg)
                                .join(", ")}
                        </Typography>
                    </>
                )}
            </CardContent>
        </Card>
    );
}
