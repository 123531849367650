import { GridField } from "@/components/datagrid/grid-field";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import type { RepositoryType } from "../keyword";

export function KeywordOverviewColumns() {
    const { t } = useTranslation("form");
    const columns: GridColDef<RepositoryType>[] = [
        GridField<RepositoryType>({
            field: "keyword_id",
            headerName: "ID",
            width: 300,
        }),
        GridField<RepositoryType>({
            field: "keyword",
            headerName: "Keyword",
            width: 150,
        }),
        GridField<RepositoryType>({
            field: "description",
            headerName: t("columns.description"),
        }),
        GridField<RepositoryType>({
            field: "keyword_group",
            headerName: t("columns.keywordGroup"),
            width: 150,
        }),
        GridField<RepositoryType>({
            field: "created_at",
            headerName: t("columns.createdAt"),
        }),
        GridField<RepositoryType>({
            field: "updated_at",
            headerName: t("columns.updatedAt"),
        }),
    ];
    return columns;
}
