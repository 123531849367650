import { Icon } from "@/theme";
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Stack,
    styled,
    Typography,
} from "@mui/material";
import {
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)({
    "& .MuiInputBase-input": {
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontWeight: 400,
    },
    "& .MuiInputBase-root": {
        borderRadius: "16px",
        height: "32px",
        width: "320px",
    },
});

export const DataGridToolbar = ({
    clearFilters,
    title,
    ...props
}: GridToolbarContainerProps & {
    clearFilters: () => void;
    title: string;
}) => {
    const { t } = useTranslation("overview");
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(
        null,
    );
    const open = Boolean(anchorElement);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    return (
        <GridToolbarContainer {...props}>
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 2,
                    width: "100%",
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    gap={2}
                    justifyContent="flex-start"
                >
                    <Icon name="Star" size="sm" />
                    <Typography variant="h3">{title}</Typography>
                </Stack>
                <Stack direction="row" gap={1} justifyContent="flex-end">
                    <IconButton aria-label="kebab" onClick={handleClick}>
                        <Icon name="Kebab" size="xs" />
                    </IconButton>
                    <Menu
                        anchorEl={anchorElement}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                        onClose={handleClose}
                        open={open}
                        transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                        }}
                    >
                        <MenuItem onClick={handleClose}>
                            <Icon name="Print" size="sm" />
                            <Typography
                                sx={(theme) => ({
                                    color: theme.palette.text.primary,
                                    fontSize: "14px",
                                    padding: "10px",
                                })}
                            >
                                {t("print")}
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Icon name="Download1" size="sm" />
                            <Typography
                                sx={(theme) => ({
                                    color: theme.palette.text.primary,
                                    fontSize: "14px",
                                    padding: "10px",
                                })}
                            >
                                {t("download")}
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Icon name="Email" size="sm" />
                            <Typography
                                sx={(theme) => ({
                                    color: theme.palette.text.primary,
                                    fontSize: "14px",
                                    padding: "10px",
                                })}
                            >
                                {t("email")}
                            </Typography>
                        </MenuItem>
                    </Menu>
                    <IconButton aria-label="close">
                        <Icon name="CloseX" size="sm" />
                    </IconButton>
                </Stack>
            </Box>
            <Stack
                alignItems="center"
                direction="row"
                gap={3}
                justifyContent="center"
                marginBottom="25px"
            >
                <StyledGridToolbarQuickFilter
                    placeholder={t("searchBar")}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="clear"
                                        onClick={clearFilters}
                                    >
                                        <Icon name="CloseX" size="sm" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon
                                        color="#8A8A8A"
                                        name="Search"
                                        size="xs"
                                    />
                                </InputAdornment>
                            ),
                        },
                    }}
                    variant="outlined"
                />
                <Button
                    startIcon={<Icon name="ControlPanel" size="sm" />}
                    sx={(theme) => ({
                        color: theme.palette.text.primary,
                    })}
                >
                    <Typography
                        sx={(theme) => ({
                            color: theme.palette.text.primary,
                            fontSize: "14px",
                        })}
                    >
                        {t("adjust")}
                    </Typography>
                </Button>
            </Stack>
        </GridToolbarContainer>
    );
};
