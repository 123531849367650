import BalanceCodesOverviewProvider from "./overview/balance-codes";
import ContactOverviewProvider from "./overview/contact";
import CostCenterOverviewProvider from "./overview/cost-center";
import JobFunctionOverviewProvider from "./overview/job-function";
import KeywordOverviewProvider from "./overview/keyword";

export const providers = {
    balance_codes: BalanceCodesOverviewProvider,
    contact: ContactOverviewProvider,
    cost_centres: CostCenterOverviewProvider,
    function_type: JobFunctionOverviewProvider,
    job_function: JobFunctionOverviewProvider,
    keyword: KeywordOverviewProvider,
};

export type Provider = (typeof providers)[keyof typeof providers];
export type ProviderKeys = keyof typeof providers;

export default function useProvider(name: ProviderKeys) {
    return providers[name];
}
