import { createRoute } from "@tanstack/react-router";

import { EditPage } from "../pages/edit-page";
import { cardLayoutRoute } from "./layouts";

const editRoute = createRoute({
    component: EditPage,
    getParentRoute: () => cardLayoutRoute,
    path: "/edit/$provider/$editId",
});

export default editRoute;
