import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { financialYearAtom, periodAtom } from "../balance-atoms";
import OptionDrawerSelect from "./option-drawer-select";

export function BalanceTimeRangeOptions() {
    const { t } = useTranslation("balance");
    const setFinancialYear = useSetAtom(financialYearAtom);
    const setPeriod = useSetAtom(periodAtom);
    return (
        <>
            <OptionDrawerSelect
                items={[
                    { label: "2024", value: "2024" },
                    { label: "2023", value: "2023" },
                    { label: "2022", value: "2022" },
                ]}
                label={t("label.bookyear")}
                onChange={(year) => {
                    setFinancialYear(Number.parseInt(year));
                }}
            />
            <OptionDrawerSelect
                items={[
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "5", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                    { label: "8", value: "8" },
                    { label: "9", value: "9" },
                    { label: "10", value: "10" },
                    { label: "11", value: "11" },
                    { label: "12", value: "12" },
                ]}
                label={t("label.period")}
                onChange={(period) => {
                    setPeriod(Number.parseInt(period));
                }}
            />
        </>
    );
}
