import { GridField } from "@/components/datagrid/grid-field";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import type { RepositoryType } from "../contact";

export function ContactOverviewColumns() {
    const { t } = useTranslation("form");
    const columns: GridColDef<RepositoryType>[] = [
        GridField<RepositoryType>({
            field: "contact_id",
            headerName: "ID",
            width: 300,
        }),
        GridField<RepositoryType>({
            field: "name",
            headerName: "Name",
            width: 150,
        }),
        GridField<RepositoryType>({
            field: "e_mail_address_default",
            headerName: t("columns.email"),
        }),
        GridField<RepositoryType>({
            field: "telephone_number",
            headerName: t("columns.phone"),
            width: 150,
        }),
        GridField<RepositoryType>({
            field: "created_at",
            headerName: t("columns.createdAt"),
        }),
        GridField<RepositoryType>({
            field: "updated_at",
            headerName: t("columns.updatedAt"),
        }),
    ];
    return columns;
}
