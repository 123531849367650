import { createRoute } from "@tanstack/react-router";

import IndexPage from "../pages/index-page";
import { heroLayoutRoute } from "./layouts";

const indexRoute = createRoute({
    component: IndexPage,
    getParentRoute: () => heroLayoutRoute,
    path: "/",
});

export default indexRoute;
