import { GridField } from "@/components/datagrid/grid-field";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import type { RepositoryType } from "../cost-center";

export function CostCenterOverviewColumns() {
    const { t } = useTranslation("form");
    const columns: GridColDef<RepositoryType>[] = [
        GridField<RepositoryType>({
            field: "cost_center_id",
            headerName: "ID",
            width: 300,
        }),
        GridField<RepositoryType>({
            field: "cost_code",
            headerName: "Cost Code",
            width: 150,
        }),
        GridField<RepositoryType>({
            field: "description",
            headerName: t("columns.description"),
        }),
        GridField<RepositoryType>({
            field: "memo",
            headerName: t("columns.memo"),
            width: 150,
        }),
        GridField<RepositoryType>({
            field: "created_at",
            headerName: t("columns.createdAt"),
        }),
        GridField<RepositoryType>({
            field: "updated_at",
            headerName: t("columns.updatedAt"),
        }),
    ];
    return columns;
}
