import { commonAPI } from "@/api";
import { ErrorCard } from "@/components/general/cards";
import { useCommonID } from "@/hooks/queries/use-common-id";
import { darkNeutral, lightNeutral } from "@/theme/colors";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Grid2,
    Typography,
} from "@mui/material";
import React from "react";

export function CompanyOverviewPage() {
    const { company, customer } = useCommonID();

    const {
        data: administrations,
        error,
        isError,
        isPending,
    } = commonAPI.useQuery(
        "get",
        "/common-api/v1/{cash_customer}/{company_id}/administration_datas",
        {
            params: {
                path: {
                    cash_customer: customer,
                    company_id: company,
                },
            },
        },
    );

    if (isPending) {
        return <CircularProgress />;
    }

    if (isError) {
        return <ErrorCard error={error} />;
    }

    return (
        <Box
            sx={(theme) => ({
                backgroundColor: lightNeutral[150],
                ...theme.applyStyles("dark", {
                    backgroundBlendMode: "soft-light",
                    backgroundColor: "#212426",
                }),
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "calc(100vh - 64px - 48px - 10px)", // 64px = AppBar, 48px = BottomNavigation, 10px = padding
                overflow: "auto",
                width: "100%",
            })}
        >
            <Grid2
                container
                spacing={2}
                sx={(theme) => ({
                    backgroundColor: lightNeutral[50.2],
                    ...theme.applyStyles("dark", {
                        backgroundColor: darkNeutral[50.2],
                    }),

                    borderRadius: "8px",
                    margin: 5,
                    marginTop: 5,
                    padding: "20px",
                    width: "1000px",
                })}
            >
                <Grid2 size={12}>
                    <Typography
                        sx={{
                            fontFamily: "Ubuntu",
                            fontSize: "16px",
                            fontWeight: 500,
                            paddingLeft: "10px",
                        }}
                        variant="subtitle1"
                    >
                        Mijn administraties
                    </Typography>
                </Grid2>

                <Grid2
                    container
                    spacing={2}
                    sx={{
                        maxHeight: "220px",
                        overflowY: "auto",
                        padding: "10px",
                        width: "100%",
                    }}
                >
                    {administrations?.items.map((administration, index) => (
                        <Grid2 key={index} size={4}>
                            <AdministrationOverviewCard
                                caption={administration.last_upload ?? ""}
                            >
                                {administration.name}
                            </AdministrationOverviewCard>
                        </Grid2>
                    ))}
                </Grid2>
            </Grid2>
        </Box>
    );
}

function AdministrationOverviewCard({
    caption,
    children,
}: {
    caption: string;
    children?: React.ReactNode;
}) {
    return (
        <Card
            elevation={1}
            sx={{
                cursor: "pointer",
            }}
            variant="elevation"
        >
            <CardContent
                sx={[
                    (theme) => ({
                        background: "url(/assets/cash-register.svg) no-repeat",
                        backgroundColor: lightNeutral[150],
                        height: "100%",
                        width: "100%",
                        ...theme.applyStyles("dark", {
                            backgroundBlendMode: "soft-light",
                            backgroundColor: "#212426",
                        }),
                    }),
                ]}
            >
                <Grid2 container>
                    <Grid2 size={10}>
                        <Typography sx={{ paddingBottom: 0 }} variant="h6">
                            {children}
                        </Typography>
                        <Typography variant="caption">{caption}</Typography>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    );
}
