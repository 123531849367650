import { currencyAtom } from "@/atoms";
import { Currency } from "@/default";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

import OptionDrawerSelect from "./option-drawer-select";

export default function BalanceMiscOptions() {
    const setCurrency = useSetAtom(currencyAtom);
    const { t } = useTranslation("balance");
    return (
        <OptionDrawerSelect
            items={[
                { label: "Euro € (standaard)", value: "EUR" },
                {
                    label: "Dollars $",
                    value: "USD",
                },
            ]}
            label={t("label.currency")}
            onChange={(currency) => {
                setCurrency(currency as Currency);
            }}
        />
    );
}
