import { commonIDAtom } from "@/atoms";
import { useAtomValue } from "jotai";
/**
 * A hook that provides the currently selected CASH company (administratie) and CASH customer IDs.
 * @returns The company and customer IDs.
 */
export function useCommonID() {
    const { company, customer } = useAtomValue(commonIDAtom);
    return { company, customer };
}
