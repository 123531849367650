import { financialAPI } from "@/api";
import { OverviewDataGrid } from "@/components/datagrid/overview-datagrid";
import { ErrorState, PendingState } from "@/components/general/state";
import { usePagedFinancialQueryParameters } from "@/hooks/queries/use-paged-financial-query-parameters";
import { components } from "financialapi.openapi";
import { useTranslation } from "react-i18next";

import BalanceCodesOverviewColumns from "./columns/balance-codes";

export type RepositoryType = components["schemas"]["BalanceCodeResponse"];

export default function BalanceCodesOverviewProvider() {
    const { t } = useTranslation("form");
    const rowID: keyof RepositoryType = "balance_code_id";
    const provider = "balance_codes";
    const title = t("titles.balanceCode");
    const columns = BalanceCodesOverviewColumns();

    const {
        pagedFinancialQueryParameters,
        paginationModel,
        setPaginationModel,
    } = usePagedFinancialQueryParameters();

    const { data, error, isError, isPending } = financialAPI.useQuery(
        "get",
        "/fin-api/v1/{cash_customer}/{company_id}/balance_codes",
        pagedFinancialQueryParameters,
    );

    const datagridProps = {
        paginationModel: paginationModel,
        provider: provider,
        rowID: rowID,
        setPaginationModel: setPaginationModel,
        title: title,
    };

    if (isPending) {
        return <PendingState />;
    }

    if (isError) {
        return <ErrorState error={error} />;
    }

    return (
        <OverviewDataGrid columns={columns} data={data} {...datagridProps} />
    );
}
