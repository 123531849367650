import { commonAPI } from "@/api";
import { OverviewDataGrid } from "@/components/datagrid/overview-datagrid";
import { ErrorState, PendingState } from "@/components/general/state";
import { usePagedCommonQueryParameters } from "@/hooks/queries/use-paged-common-query-parameters";
import { components } from "commonapi.openapi";
import { useTranslation } from "react-i18next";

import { CostCenterOverviewColumns } from "./columns/cost-center";

export type RepositoryType = components["schemas"]["CostCenterResponse"];

export default function CostCenterOverviewProvider() {
    const { t } = useTranslation("form");
    const rowID: keyof RepositoryType = "cost_center_id";
    const provider = "cost_center";
    const title = t("titles.costCenter");
    const columns = CostCenterOverviewColumns();

    const { pagedCommonQueryParameters, paginationModel, setPaginationModel } =
        usePagedCommonQueryParameters();

    const { data, error, isError, isPending } = commonAPI.useQuery(
        "get",
        "/common-api/v1/{cash_customer}/{company_id}/cost_centers",
        pagedCommonQueryParameters,
    );

    const datagridProps = {
        paginationModel: paginationModel,
        provider: provider,
        rowID: rowID,
        setPaginationModel: setPaginationModel,
        title: title,
    };

    if (isPending) {
        return <PendingState />;
    }

    if (isError) {
        return <ErrorState error={error} />;
    }

    return (
        <OverviewDataGrid columns={columns} data={data} {...datagridProps} />
    );
}
