import { ListItemButton, ListItemButtonProps, Stack } from "@mui/material";
import { createLink } from "@tanstack/react-router";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { FlattenedMenuItem } from "./subnavigation";
import { SubNavigationTopMenuButton } from "./subnavigation-topmenu-button";

const ListItemButtonLinkComponent = forwardRef<
    HTMLAnchorElement,
    ListItemButtonProps
>((props, ref) => {
    return <ListItemButton component={"a"} ref={ref} {...props} />;
});

const ListItemButtonLink = createLink(ListItemButtonLinkComponent);

export default function TopMenuButtons({
    handleClick,
    menu,
    menuKey,
}: {
    handleClick: (
        event: React.MouseEvent<HTMLButtonElement>,
        id: string,
    ) => void;
    menu: FlattenedMenuItem[];
    menuKey: null | string;
}) {
    const { t } = useTranslation("root");

    const handleButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        topLevelMenu: FlattenedMenuItem,
    ) => {
        if (topLevelMenu.children.length > 0) {
            handleClick(event, topLevelMenu.id);
        }
    };

    return (
        <Stack direction={"row"}>
            {menu.map((topLevelMenu) =>
                topLevelMenu.link ? (
                    <ListItemButtonLink
                        key={topLevelMenu.id}
                        sx={{
                            borderRadius: "10px",
                            color: "primary",
                            fontSize: "14px",
                            fontWeight: 500,
                            height: "48px",
                            paddingX: "1em",
                            textTransform: "none",
                            WebkitFlexGrow: 0,
                        }}
                        to={"/" + topLevelMenu.link}
                    >
                        {t(
                            `mega-menu.${topLevelMenu.label}`,
                            topLevelMenu.label,
                        )}
                    </ListItemButtonLink>
                ) : (
                    <SubNavigationTopMenuButton
                        active={menuKey === topLevelMenu.id}
                        dropdown={topLevelMenu.children.length > 0}
                        key={topLevelMenu.id}
                        label={t(
                            `mega-menu.${topLevelMenu.label}`,
                            topLevelMenu.label,
                        )}
                        onClick={(event) => {
                            handleButtonClick(event, topLevelMenu);
                        }}
                    />
                ),
            )}
        </Stack>
    );
}
