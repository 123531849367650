import CardLayout from "@/layouts/card";
import HeroLayout from "@/layouts/hero";
import { createRoute } from "@tanstack/react-router";

import rootRoute from "./__root";

export const cardLayoutRoute = createRoute({
    component: CardLayout,
    getParentRoute: () => rootRoute,
    id: "card-layout",
});

export const heroLayoutRoute = createRoute({
    component: HeroLayout,
    getParentRoute: () => rootRoute,
    id: "hero-layout",
});
