import {
    Button,
    Card,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Input,
    InputBase,
    List,
    ListItem,
    Stack,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { Icon } from "@theme/index";
import Fuse, { FuseResult } from "fuse.js";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { globalAPI } from "../../api";

const Search = styled("div")(({ theme }) => ({
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.15),
    },
    border: "1px solid",
    borderColor: alpha(theme.palette.common.white, 0.55),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[0],
    marginLeft: 0,
    marginRight: theme.spacing(2),
    position: "relative",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
    },
    width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    padding: theme.spacing(0, 2),
    pointerEvents: "none",
    position: "absolute",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
        transition: theme.transitions.create("width"),
        width: "100%",
    },
    color: theme.palette.common.white,
    width: "100%",
}));

export default function SearchBar() {
    const { t } = useTranslation("root");
    const [open, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");
    const [searchResults, setSearchResults] = useState<FuseResult<string>[]>(
        [],
    );
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFocus = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const { data: menu } = globalAPI.useQuery(
        "get",
        "/global-api/v1/menu_items_organized",
    );

    const fuse = useMemo(() => {
        const options = {
            includeMatches: true,
            threshold: 0.25,
        };

        if (!menu) {
            return new Fuse([], options);
        }

        const list =
            menu.menuItems
                ?.flatMap((item) => item.menuItems)
                .flatMap((subItem) => subItem?.menuItems)
                .map((item) => {
                    const { label } = item?.menuItem ?? { label: "" };
                    return label;
                }) ?? [];

        return new Fuse(list, options);
    }, [menu]);

    useEffect(() => {
        setSearchResults(fuse.search(value));
    }, [value, fuse]);

    return (
        <div>
            <Search>
                <SearchIconWrapper>
                    <Icon color="white" name="Search" size="sm" />
                </SearchIconWrapper>
                <StyledInputBase
                    onClick={handleFocus}
                    onFocus={() => {
                        inputRef.current?.focus();
                    }}
                    placeholder={t("navigation.search.label")}
                />
            </Search>
            <Dialog
                fullWidth={true}
                onClose={handleClose}
                open={open}
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start",
                    },
                    " & .MuiDialog-paper": {
                        margin: "0.8em",
                    },
                }}
            >
                <DialogTitle>
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <Icon color="inherit" name="Search" size="sm" />
                        <Input
                            fullWidth
                            inputRef={inputRef}
                            margin="dense"
                            onChange={handleChange}
                            type="text"
                            value={value}
                        />
                    </Stack>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Stack spacing={1}>
                        <List>
                            {searchResults.map((result) => (
                                <SearchResultListItem key={result.refIndex}>
                                    {[...result.item].map((char, index) => {
                                        const match = result.matches?.some(
                                            (match) => {
                                                return match.indices.some(
                                                    ([start, end]) => {
                                                        return (
                                                            index >= start &&
                                                            index <= end
                                                        );
                                                    },
                                                );
                                            },
                                        );
                                        if (match) {
                                            return (
                                                <span key={index}>{char}</span>
                                            );
                                        }
                                        return <span key={index}>{char}</span>;
                                    })}
                                </SearchResultListItem>
                            ))}
                        </List>
                    </Stack>
                </DialogContent>
            </Dialog>
        </div>
    );
}

function SearchResultListItem({ children }: { children: React.ReactNode }) {
    return (
        <ListItem sx={{ paddingY: "0.3em" }}>
            <Card
                elevation={4}
                sx={{
                    cursor: "pointer",
                    flexGrow: 1,
                    fontWeight: "bold",
                    padding: "0.5em",
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent={"space-between"}
                >
                    <span style={{ paddingLeft: "1em" }}>{children}</span>
                    <Button variant="contained">
                        <Icon name="ChevronRight" size="md" />
                    </Button>
                </Stack>
            </Card>
        </ListItem>
    );
}
