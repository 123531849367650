import type { IChangeEvent } from "@rjsf/core";
import type { RJSFSchema } from "@rjsf/utils";

import { commonAPI } from "@/api";
import { ErrorCard } from "@/components/general/cards";
// import { financialAPI } from "@/api";
// import { globalAPI } from "@/api";
import { useCommonEditPathParams } from "@/hooks/queries/use-common-edit-params";
import { realFormUIType } from "@/pages/edit-page";
import { CircularProgress } from "@mui/material";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useNavigate } from "@tanstack/react-router";
// import { components, operations, paths } from "financialapi.openapi";
// import { components, operations, paths } from "globalapi.openapi";
import { components, operations, paths } from "commonapi.openapi";
import { useTranslation } from "react-i18next";

import { jobFunctionFormSchema } from "./schema/job-function"; // Import the form schema here

type EditPath = operations["put_function_type"]["parameters"]["path"]; // Edit this type
type FormType = components["schemas"]["FunctionTypeRequest"]; // Edit this type

export default function JobFunctionForm() {
    const { t } = useTranslation("form");

    /* Edit these constants */

    const provider = "function_type";
    const dataID = "function_type_data";
    const title = "Job Function";
    const queryPath: keyof paths =
        "/common-api/v1/{cash_customer}/{company_id}/function_type/{function_type_id}";
    const schema = jobFunctionFormSchema;

    ////////////////////////////////////////
    const editPath = useCommonEditPathParams<EditPath>(`${provider}_id`);
    const navigate = useNavigate({ from: "/edit/$provider/$editId" });

    const {
        data: formUISchema,
        error: UISchemaError,
        isError: isUISchemaError,
        isPending: isUISchemaPending,
    } = commonAPI.useQuery("get", "/common-api/v1/form/{form_name}", {
        params: {
            path: {
                form_name: provider,
            },
        },
    });

    const {
        data: formData,
        error: formDataError,
        isError: isformDataError,
        isPending: isformDataPending,
    } = commonAPI.useQuery("get", queryPath, {
        params: {
            path: editPath,
        },
    });

    const mutation = commonAPI.useMutation("put", queryPath, {
        onSuccess: async () => {
            await navigate({
                params: {
                    provider: provider,
                },
                to: "/overview/$provider",
            });
        },
        params: {
            path: editPath,
        },
    });

    const isError = isUISchemaError || isformDataError;
    const error = UISchemaError || formDataError;
    const isPending = isUISchemaPending || isformDataPending || !formUISchema;

    const uiSchema = (formUISchema as unknown as realFormUIType)?.message.ui;

    if (isPending) {
        return <CircularProgress />;
    }

    if (isError) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <h3>
                {t("edit")} {title}
            </h3>
            {formData && uiSchema && (
                <Form
                    formData={formData}
                    onSubmit={(
                        data: IChangeEvent<unknown, RJSFSchema, FormType>,
                    ) => {
                        mutation.mutate({
                            body: {
                                [dataID]: data.formData,
                            } as { [dataID]: FormType },
                            params: {
                                path: editPath,
                            },
                        });
                    }}
                    schema={schema}
                    uiSchema={uiSchema}
                    validator={validator}
                />
            )}
        </>
    );
}
