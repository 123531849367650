import type { ThemeOptions } from "@mui/material";

export const components: ThemeOptions["components"] = {
    MuiAlert: {
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                ...(ownerState.severity === "success" && {
                    border: `${theme.palette.success.main} 1px solid`,
                }),
                ...(ownerState.severity === "info" && {
                    border: `${theme.palette.info.main} 1px solid`,
                }),
                ...(ownerState.severity === "warning" && {
                    border: `${theme.palette.warning.main} 1px solid`,
                }),
                ...(ownerState.severity === "error" && {
                    border: `${theme.palette.error.main} 1px solid`,
                }),
            }),
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                borderWidth: 1,
                lineHeight: 1.2,
                textTransform: "none" as const,
            },
        },
    },
    MuiButtonBase: {
        styleOverrides: {
            root: {
                textTransform: "none",
            },
        },
    },
    MuiCssBaseline: {
        styleOverrides: (theme) => ({
            "*::-webkit-scrollbar": {
                width: "8px",
            },
            "*::-webkit-scrollbar-thumb": {
                backgroundColor:
                    theme.palette.mode === "dark"
                        ? "rgba(175, 188, 196, 1)"
                        : "rgba(107, 107, 107, 1)",
                borderRadius: "8px",
            },
            "*::-webkit-scrollbar-track": {
                backgroundColor:
                    theme.palette.mode === "dark"
                        ? "rgba(76, 84, 91, 1)"
                        : "rgba(247, 247, 247, 1)",
                borderRadius: "8px",
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.1)",
            },
        }),
    },
    MuiList: {
        styleOverrides: {
            root: {
                paddingBottom: 0,
                paddingTop: 0,
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: ({ theme }) => ({
                "&.Mui-selected": {
                    color: theme.palette.text.secondary,
                    fontWeight: "bold",
                },
                color: theme.palette.text.secondary,
                lineHeight: "inherit",
                minHeight: "40px",
                padding: "0 16px",
                textTransform: "none",
            }),
        },
    },
    MuiTableCell: {
        styleOverrides: {
            head: {
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
            },
        },
    },
    MuiTabs: {
        styleOverrides: {
            indicator: ({ theme }) => ({
                backgroundColor: theme.palette.success.main,
                height: 4,
            }),
            root: {
                borderBottom: "1px solid",
                borderColor: "rgba(0, 0, 0, 0.12)",
                marginBottom: "1em",
                minHeight: "40px",
            },
        },
    },
};
