import { IconName } from "@/theme";
import {
    darkNeutral,
    darkOrange,
    lightNeutral,
    lightOrange,
} from "@/theme/colors";
import { Icon } from "@/theme/icon";
import {
    Box,
    Button,
    Card,
    CardHeader,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import BorderLinearProgress from "../general/linear-progress-bar";

export default function CompanyStatusWidget() {
    const { t } = useTranslation("widgets");

    const dummyListItems = [
        {
            icon: "Error",
            id: 1,
            primary: "Dien je OB-aangifte in",
            secondary: "Nog 2 dagen tot de uiterste datum",
        },
        {
            icon: "Error",
            id: 2,
            primary: "Dien je ICP-aangifte in",
            secondary: "Nog 4 dagen tot de uiterste datum",
        },
        {
            icon: "Error",
            id: 3,
            primary: "Fiatteer je aangifte",
            secondary: "Nog 5 dagen tot de uiterste datum",
        },
        {
            icon: "Error",
            id: 4,
            primary: "Je aangifte is gefiatteerd",
            secondary: "Nog 7 dagen tot de uiterste datum",
        },
        {
            icon: "Error",
            id: 5,
            primary: "Match je bankmutaties",
            secondary: "Er zijn 8 te bankmutaties te matchen",
        },
        {
            icon: "Warning",
            id: 6,
            primary: "Match je UBL-factuurrregels",
            secondary: "Er zijn 5 regel te machten in het dagboek",
        },
    ];
    return (
        <Card
            elevation={3}
            sx={(theme) => ({
                backgroundColor: "#ECF1F9", //this removes a inexplicable white background
                backgroundImage:
                    theme.palette.mode === "dark"
                        ? "url(/assets/widget-bg-dark.svg)"
                        : "url(/assets/widget-bg.svg)",
                backgroundPosition: "right 15% top 10%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                minHeight: "550px",
                width: "384px",
            })}
            variant="elevation"
        >
            <CardHeader
                sx={{ paddingLeft: "24px" }}
                title={t("CompanyStatus.title")}
                titleTypographyProps={{
                    fontFamily: "Ubuntu",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    mt: "31px",
                    variant: "h4",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                }}
            >
                <Box sx={{ width: "100%" }}>
                    <BorderLinearProgress
                        height={16}
                        primarycolor={lightOrange[600]}
                        secondarycolor={darkOrange[600]}
                        sx={{ borderRadius: "8px" }}
                        value={30}
                        variant="determinate"
                    />
                    <Box
                        sx={{
                            alignItems: "center",
                            alignSelf: "stretch",
                            display: "flex",
                            justifyContent: "space-between",
                            mt: 1,
                        }}
                    >
                        <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                            {t("CompanyStatus.taskInfo")}
                        </Typography>
                        <Typography
                            sx={{ color: "text.secondary", ml: 2 }}
                            variant="body2"
                        >{`${Math.round(30)}%`}</Typography>
                    </Box>
                </Box>
            </Box>
            <CardHeader
                sx={{ paddingLeft: "24px" }}
                title={t("CompanyStatus.task")}
                titleTypographyProps={{
                    fontFamily: "Ubuntu",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    marginTop: "25px",
                    variant: "h4",
                }}
            />

            <List
                dense
                sx={{
                    maxHeight: "20em",
                    mr: 2,
                    overflowY: "auto",
                    paddingTop: "0",
                }}
            >
                {dummyListItems.map((item) => (
                    <CompanyTaskListItem
                        icon={item.icon}
                        key={item.id}
                        primary={item.primary}
                        secondary={item.secondary}
                    />
                ))}
            </List>
        </Card>
    );
}

function CompanyTaskListItem({
    icon,
    primary,
    secondary,
}: {
    icon: string;
    primary: string;
    secondary: string;
}) {
    return (
        <ListItem>
            <ListItemButton
                sx={(theme) => ({
                    backgroundColor: lightNeutral[50.2],
                    ...theme.applyStyles("dark", {
                        backgroundColor: darkNeutral[50.2],
                    }),
                    borderRadius: "8px",
                    boxShadow: "0px 2px 4px rgba(49, 98, 69, 0.16)",
                    paddingBottom: "0.5em",
                })}
            >
                <ListItemIcon
                    sx={{
                        alignSelf: "flex-start",
                        minWidth: "auto",
                        mr: 1,
                        mt: 1,
                    }}
                >
                    <Icon name={icon as IconName} size="sm" />
                </ListItemIcon>
                <ListItemText
                    primary={primary}
                    primaryTypographyProps={{
                        fontFamily: "Ubuntu",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                    }}
                    secondary={secondary}
                    secondaryTypographyProps={{
                        fontFamily: "Open sans",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                    }}
                />
                <Button
                    sx={{ height: "32px", minWidth: "32px", padding: 0 }}
                    variant="contained"
                >
                    <Icon name="ChevronRight" size="sm" />
                </Button>
            </ListItemButton>
        </ListItem>
    );
}
