import { useAtom } from "jotai";

import { balanceDrawerAtom } from "../components/balance/balance-atoms";

export function useToggleDrawer() {
    const [open, setOpen] = useAtom(balanceDrawerAtom);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    return toggleDrawer;
}
