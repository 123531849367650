import {
    OptionDrawerActions,
    OptionDrawerOptionGroup,
} from "@/components/general/option-drawer";
import { Grid2 } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useToggleDrawer } from "../../../hooks/use-toggle-drawer";
import { CancelButton, SaveButton } from "../../general/buttons";
import BalanceDisplayOptions from "./balance-display-options";
import BalanceMiscOptions from "./balance-misc-options";
import { BalanceTimeRangeOptions } from "./balance-timerange-options";

export default function OptionDrawerContent() {
    const { t } = useTranslation("balance");
    const toggleDrawer = useToggleDrawer();
    return (
        <Grid2
            component={"form"}
            container
            gap={2}
            onSubmit={(event) => {
                event.preventDefault();
                toggleDrawer();
            }}
        >
            <OptionDrawerOptionGroup title={t("options.display")}>
                <BalanceDisplayOptions />
            </OptionDrawerOptionGroup>
            <OptionDrawerOptionGroup title={t("options.timeRange")}>
                <BalanceTimeRangeOptions />
            </OptionDrawerOptionGroup>
            <OptionDrawerOptionGroup last title={t("options.misc")}>
                <BalanceMiscOptions />
            </OptionDrawerOptionGroup>
            <OptionDrawerActions>
                <CancelButton onClick={toggleDrawer} />
                <SaveButton />
            </OptionDrawerActions>
        </Grid2>
    );
}
