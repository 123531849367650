import { darkNeutral, lightNeutral } from "@/theme/colors";
import {
    List,
    ListItemButton,
    ListItemButtonProps,
    styled,
    Typography,
} from "@mui/material";
import { createLink } from "@tanstack/react-router";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const ActiveListItemButton = styled(ListItemButton)(({ theme }) => ({
    "&:hover": {
        background: theme.palette.primary.main,
        transition: "background 0.3s ease",
    },
    background: theme.palette.primary.main,
    borderRadius: "10px",
    color: "#fff",
    paddingY: "1em",
    transition: "background 0.3s ease",
}));

const ListItemButtonLinkComponent = forwardRef<
    HTMLAnchorElement,
    ListItemButtonProps
>((props, ref) => {
    return <ListItemButton component={"a"} ref={ref} {...props} />;
});

const ListItemButtonLink = createLink(ListItemButtonLinkComponent);

export function SubNavigationCategoryList({
    activeMenu,
    hasMenuItems,
    items,
    setActiveMenu,
    setMenuContent,
}: {
    activeMenu: null | string;
    hasMenuItems: boolean;
    items: {
        active?: boolean;
        i18n_tag: string;
        id: string;
        label: string;
        link?: null | string;
    }[];
    setActiveMenu: (value: string, link: null | string) => void;
    setMenuContent: (id: string) => void;
}) {
    const { t } = useTranslation("root");

    const handleMouseEnter = (id: string, link: null | string) => {
        setActiveMenu(id, link);
        setMenuContent(id);
    };

    return (
        <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
            {hasMenuItems ? (
                <List
                    sx={(theme) => ({
                        backgroundColor:
                            theme.palette.mode === "dark"
                                ? darkNeutral[100.1]
                                : lightNeutral[100.1],
                        flexGrow: 1,
                        fontSize: "14px",
                        fontWeight: "bold",
                        overflow: "hidden",
                        padding: "1em",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    })}
                >
                    {items.map((item) =>
                        item.id === activeMenu ? (
                            <ActiveListItemButton
                                key={item.id}
                                onMouseEnter={() =>
                                    handleMouseEnter(item.id, item.link ?? null)
                                }
                                sx={{ paddingY: "1em" }} // weird hack to make padding work
                            >
                                {t(`mega-menu.${item.i18n_tag}`, item.label)}
                            </ActiveListItemButton>
                        ) : (
                            <ListItemButton
                                key={item.id}
                                onMouseEnter={() =>
                                    handleMouseEnter(item.id, item.link ?? null)
                                }
                                sx={{ borderRadius: "10px", paddingY: "1em" }}
                            >
                                {t(`mega-menu.${item.i18n_tag}`, item.label)}
                            </ListItemButton>
                        ),
                    )}
                </List>
            ) : (
                <List sx={{ flexGrow: 1 }}>
                    {items?.map((item) => (
                        <ListItemButtonLink
                            key={item.id}
                            onClick={() => {
                                setActiveMenu(item.id, item.link ?? null);
                                setMenuContent(item.id);
                            }}
                            sx={{ borderRadius: "10px", paddingY: "1em" }}
                            to={"/" + item.link!}
                        >
                            <Typography color="textPrimary" variant="body2">
                                {item?.label}
                            </Typography>
                        </ListItemButtonLink>
                    ))}
                </List>
            )}
        </div>
    );
}
