import BalanceTableLayout from "@/components/balance/balance-table-layout";
import OptionDrawerContent from "@/components/balance/option-drawer/option-drawer-content";
import { ErrorCard } from "@/components/general/cards";
import { BalanceTable } from "@components/balance/balance-table";
import { CircularProgress, GlobalStyles } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
    Activa,
    Passiva,
    useBalanceDataQuery,
} from "../hooks/queries/use-balance-data-query";

export function BalancePage() {
    const { data, error, isError, isPending } = useBalanceDataQuery();
    const { t } = useTranslation("balance");

    if (isPending) {
        return <CircularProgress />;
    }

    if (isError) {
        if (!isError) {
            return <ErrorCard error={"No data found"} />;
        }
        return <ErrorCard error={error} />;
    }

    const activa: Activa = {
        data: data.activa_data,
        difference: data.activa_balance_difference,
        totalAmountEndBalance: data.total_amount_activa_end_balance,
        totalAmountStartBalance: data.total_amount_activa_start_balance,
    };

    const passiva: Passiva = {
        data: data.passiva_data,
        difference: data.passiva_balance_difference,
        totalAmountEndBalance: data.total_amount_passiva_end_balance,
        totalAmountStartBalance: data.total_amount_passiva_start_balance,
    };

    return (
        <>
            <GlobalStyles
                styles={(theme) => ({
                    body: { backgroundColor: theme.palette.background.admin },
                })}
            />
            <BalanceTableLayout
                activaTable={
                    <BalanceTable label={t("activa")} tableData={activa} />
                }
                optionDrawer={<OptionDrawerContent />}
                passivaTable={
                    <BalanceTable label={t("passiva")} tableData={passiva} />
                }
            />
        </>
    );
}
