import type { RJSFSchema } from "@rjsf/utils";

export const keywordFormSchema: RJSFSchema = {
    properties: {
        description: {
            title: "Description",
            type: "string",
        },
        keyword: {
            title: "Keyword",
            type: "string",
        },
        keyword_group: {
            title: "Keyword Group",
            type: "string",
        },
        keyword_id: {
            title: "Keyword ID",
            type: "string",
        },
    },
    required: ["keyword_id", "keyword"],
    type: "object",
};
