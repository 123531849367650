import { companyIdAtom, currencyAtom, customerIdAtom } from "@/atoms";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { BalanceTableDirection } from "./balance-table-direction";

export const gridSizeAtom = atomWithStorage<BalanceTableDirection>(
    "balance/GridSize",
    BalanceTableDirection.SideBySide,
);
export const balanceDrawerAtom = atom(false);
export const periodAtom = atomWithStorage<number>("balance/period", 1);
export const financialYearAtom = atomWithStorage<number>(
    "balance/financialYear",
    new Date().getFullYear() - 1,
);

export const balanceOptionsAtom = atom((get) => {
    return {
        cash_customer: get(customerIdAtom),
        company_id: get(companyIdAtom),
        currency: get(currencyAtom),
        financial_year: get(financialYearAtom),
        period: get(periodAtom),
    };
});
