import { CircularProgress, Grid2 } from "@mui/material";
import { Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/react-router-devtools";
import { DEV } from "esm-env";
import { useEffect, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";

import Navigation from "../components/navigation/navigation";
import { LoginPage } from "./login-page";

export function RootPage() {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState<boolean>(false);

    // automatically sign-in
    useEffect(() => {
        if (
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading &&
            !hasTriedSignin
        ) {
            void auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    if (auth.isLoading) {
        return (
            <Grid2
                alignItems="center"
                container
                direction="column"
                justifyContent="center"
                spacing={0}
                sx={{ minHeight: "100vh" }}
            >
                <Grid2 size={3}>
                    <CircularProgress size={96} />
                </Grid2>
            </Grid2>
        );
    }

    if (!auth.isAuthenticated) {
        return <LoginPage error={auth.error} />;
    }

    return (
        <>
            <Navigation />
            <Grid2 component="main" container>
                <Outlet />
                {DEV && <TanStackRouterDevtools />}
            </Grid2>
        </>
    );
}
