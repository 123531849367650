import type { paths as commonPaths } from "commonapi.openapi";
import type { paths as financialPaths } from "financialapi.openapi";
import type { paths as globalPaths } from "globalapi.openapi";

import { User } from "oidc-client-ts";
import createFetchClient, { Middleware } from "openapi-fetch";
import createClient from "openapi-react-query";
import { z } from "zod";

const linkSchema = z.object({
    next: z.string().nullable().optional(),
    prev: z.string().nullable().optional(),
    self: z.string(),
});

export const metaSchema = z.object({
    current: z.number(),
    links: linkSchema,
    per_page: z.number(),
});

function getUser() {
    const oidcStorage = localStorage.getItem(
        `oidc.user:${import.meta.env.VITE_OAUTH_AUTHORITY}:${import.meta.env.VITE_OAUTH_CLIENT_ID}`,
    );
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

const authMiddleware: Middleware = {
    onRequest({ request }) {
        const accessToken = getUser()?.access_token;
        if (!accessToken) {
            throw new Error("API authMiddleware: No access token found");
        }
        request.headers.set("Authorization", `Bearer ${accessToken}`);
        return request;
    },
};

export const getCommonApiClient = () => {
    const client = createFetchClient<commonPaths>({
        baseUrl: import.meta.env.VITE_COMMON_API_URL,
    });
    client.use(authMiddleware);
    return client;
};

export const getGlobalApiClient = () => {
    const client = createFetchClient<globalPaths>({
        baseUrl: import.meta.env.VITE_GLOBAL_API_URL,
    });
    client.use(authMiddleware);
    return client;
};

export const getFinancialApiClient = () => {
    const client = createFetchClient<financialPaths>({
        baseUrl: import.meta.env.VITE_FINANCIAL_API_URL,
    });
    client.use(authMiddleware);
    return client;
};

export const commonAPI = createClient(getCommonApiClient());

export const globalAPI = createClient(getGlobalApiClient());

export const financialAPI = createClient(getFinancialApiClient());
