import type { ProviderKeys } from "@/providers/providers";

import getForm from "@/forms/forms";
import editRoute from "@/routes/edit";
import { Container, Grid2 } from "@mui/material";

/**
 * Temporary placeholder for the real form UI schema.
 */
export type realFormUIType = {
    message: {
        ui: Record<string, unknown>;
    };
};

export function EditPage() {
    const { provider: providerName }: { provider: ProviderKeys } =
        editRoute.useParams();
    const Form = getForm(providerName);
    return (
        <Grid2
            alignItems="center"
            container
            justifyContent="center"
            marginBottom={4}
        >
            <Grid2>
                <Container maxWidth="sm">
                    <Form />
                </Container>
            </Grid2>
        </Grid2>
    );
}
