import editRoute from "@/routes/edit";

import { useCommonPathParams } from "./use-common-path-params";

export function useCommonEditPathParams<T>(id: string) {
    const { editId } = editRoute.useParams();
    const commonPath = useCommonPathParams();
    const editPath = { [id]: editId };
    const commonEditPath = Object.assign(commonPath, editPath);
    return commonEditPath as T;
}
