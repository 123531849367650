import type { Activa, Passiva } from "@/hooks/queries/use-balance-data-query";
import type { components } from "financialapi.openapi";

import { Icon } from "@/theme";
import { lightNeutral } from "@/theme/colors";
import { numberToColorType } from "@/utilities";
import {
    IconButton,
    IconButtonProps,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Currency } from "../general/currency";
import { Difference } from "../general/difference";
import { balanceOptionsAtom } from "./balance-atoms";

type ExpandMoreProps = IconButtonProps & {
    expand: boolean;
};

export function BalanceRow({
    balance,
    last,
}: {
    balance: components["schemas"]["BalanceData"];
    last: boolean;
}) {
    const [expanded, setExpanded] = useState<boolean>(true);
    const showExpand = balance.balance_codes.length > 1;
    return (
        <>
            <TableRow
                onClick={() => {
                    if (showExpand) {
                        setExpanded(!expanded);
                    }
                }}
                sx={{
                    cursor: showExpand ? "pointer" : "default",
                    height: last && !expanded ? "100%" : "auto",
                    verticalAlign: last && !expanded ? "top" : "inherit",
                }}
            >
                <TableCell>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{ alignItems: "center" }}
                    >
                        <span>{balance.description}</span>
                        {showExpand && (
                            <ExpandMore expand={expanded} size="small" />
                        )}
                    </Stack>
                </TableCell>
                <TableCell>
                    <Currency value={balance.start_balance} />
                </TableCell>
                <TableCell>
                    <Currency value={balance.end_balance} />
                </TableCell>
                <TableCell>
                    <Typography
                        color={numberToColorType(balance.balance_difference)}
                        fontSize="0.875rem"
                    >
                        <Currency signed value={balance.balance_difference} />
                    </Typography>
                </TableCell>
            </TableRow>
            {showExpand &&
                balance.balance_codes.map((item, index, array) => {
                    const lastRow = index === array.length - 1;
                    return (
                        <TableSubRow
                            expanded={expanded}
                            item={item}
                            key={item.balance_code_id}
                            last={last}
                            lastRow={lastRow}
                        />
                    );
                })}
        </>
    );
}

export function BalanceTable({
    label,
    tableData,
}: {
    label: string;
    tableData: Activa | Passiva;
}) {
    const { financial_year: year } = useAtomValue(balanceOptionsAtom);
    const { t } = useTranslation("balance");
    const { data, difference, totalAmountEndBalance, totalAmountStartBalance } =
        tableData;
    return (
        <TableContainer sx={{ height: "100%" }}>
            <Table size="small" sx={{ height: "100%" }}>
                <colgroup>
                    <col width="20%" />
                    <col width="5%" />
                    <col width="5%" />
                    <col width="5%" />
                </colgroup>

                <TableHead>
                    <TableRow>
                        <TableCell>{t(label)}</TableCell>
                        <TableCell>
                            {t("finalBalance")} {year - 1}
                        </TableCell>
                        <TableCell>
                            {t("finalBalance")} {year}
                        </TableCell>
                        <TableCell>{t("difference")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((balance, index, array) => {
                        const lastRow = index === array.length - 1;
                        return (
                            <BalanceRow
                                balance={balance}
                                key={balance.balance_code_id}
                                last={lastRow}
                            />
                        );
                    })}
                    <BalanceTotalRow
                        difference={difference}
                        label={label}
                        totalAmountEndBalance={totalAmountEndBalance}
                        totalAmountStartBalance={totalAmountStartBalance}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export function BalanceTotalRow({
    difference,
    label,
    totalAmountEndBalance,
    totalAmountStartBalance,
}: {
    difference: number;
    label: string;
    totalAmountEndBalance: number;
    totalAmountStartBalance: number;
}) {
    const { t } = useTranslation("form");
    return (
        <TableRow
            sx={{
                "& td": {
                    borderBottom: 0,
                    borderTop: "2px solid",
                    borderTopColor: lightNeutral[300],
                },
            }}
        >
            <TableCell>
                <Typography sx={{ fontWeight: 500 }}>
                    {t("columns.total")} {label.toLowerCase()}
                </Typography>
            </TableCell>
            <TableCell>
                <Difference
                    data-testid="currency-value"
                    sx={{ fontWeight: 500 }}
                    value={totalAmountStartBalance}
                />
            </TableCell>
            <TableCell>
                <Difference
                    sx={{ fontWeight: 500 }}
                    value={totalAmountEndBalance}
                />
            </TableCell>
            <TableCell>
                <Difference
                    sx={{
                        fontWeight: 500,
                    }}
                    value={difference}
                ></Difference>
            </TableCell>
        </TableRow>
    );
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    return (
        <IconButton {...props}>
            <Icon name="ChevronDown" size="sm" />
        </IconButton>
    );
})(({ theme }) => ({
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: "rotate(0deg)",
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: "rotate(180deg)",
            },
        },
    ],
}));

function TableSubRow({
    expanded,
    item,
    last,
    lastRow,
}: {
    expanded: boolean;
    item: components["schemas"]["SubBalanceData"];
    last: boolean;
    lastRow: boolean;
}) {
    return (
        <TableRow
            key={item.balance_code_id}
            sx={{
                "& td": { border: 0 },
                display: expanded ? "table-row" : "none",
                height: lastRow && last ? "100%" : "auto",
                verticalAlign: lastRow && last ? "top" : "inherit",
            }}
        >
            <TableCell sx={{ padding: 0, width: "15em" }}>
                <Typography
                    color="primary"
                    fontSize="0.875rem"
                    m={0}
                    p={0}
                    sx={{
                        "&:before": {
                            content: "'•'",
                            fontSize: "0.875rem",
                            paddingRight: "0.5em",
                        },
                        fontStyle: "italic",
                        marginLeft: "1em",
                        width: "15em",
                    }}
                >
                    {item.description}
                </Typography>
            </TableCell>
            <TableCell>
                <Currency value={item.start_balance} />
            </TableCell>
            <TableCell>
                <Currency value={item.end_balance} />
            </TableCell>
            <TableCell>
                <Typography
                    color={numberToColorType(item.balance_difference)}
                    fontSize="0.875rem"
                >
                    <Currency value={item.balance_difference} />
                </Typography>
            </TableCell>
        </TableRow>
    );
}
