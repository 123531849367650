import type { components } from "globalapi.openapi";

import { IconName } from "@/theme";
import { Grid2 } from "@mui/material";
import { useTranslation } from "react-i18next";

import { MegaMenuColumn } from "./megamenu-column";
import { FlattenedMenuItem } from "./subnavigation";
import { SubNavigationCategoryList } from "./subnavigation-categorylist";

type MenuItem = {
    i18n_tag?: string;
    icon?: IconName;
    label?: string;
    link?: string;
    menu_item_id?: string;
};

type SubMenu = {
    menuItem?: MenuItem;
    menuItems?: SubMenu[];
};

export default function MenuContent({
    activeMenu,
    handleSubMenuClick,
    hasMenuItems,
    menu,
    menuContent,
    menuKey,
    onItemClick,
    setMenuContentByKey,
}: {
    activeMenu: null | string;
    handleSubMenuClick: (id: string, link: null | string) => void;
    hasMenuItems: boolean;
    menu?: components["schemas"]["MenuStructureResponse"];
    menuContent: FlattenedMenuItem[];
    menuKey: null | string;
    onItemClick: (link: null | string) => void;
    setMenuContentByKey: (id: string) => void;
}) {
    const { t } = useTranslation("root");

    const findMenuItem = () =>
        menu?.menuItems?.find(
            (item) => item.menuItem?.menu_item_id === menuKey,
        );

    const mapSubMenuToFlattened = (subMenu: SubMenu): FlattenedMenuItem => {
        const { menuItem, menuItems } = subMenu;
        const {
            i18n_tag: i18nTag = "",
            icon = undefined,
            label = "",
            link = null,
            menu_item_id: menuItemId = "",
        } = menuItem || {};

        const children = menuItems?.map(mapSubMenuToFlattened) ?? [];

        return {
            children: children,
            i18n_tag: i18nTag,
            icon: icon,
            id: menuItemId,
            label: t(`mega-menu.${i18nTag}`, label),
            link: link,
        };
    };

    const getMenuItems = (): FlattenedMenuItem[] => {
        const currentMenu = findMenuItem();
        return (
            currentMenu?.menuItems
                ?.filter(
                    (item): item is SubMenu =>
                        item.menuItem !== undefined && item.menuItem !== null,
                )
                .map((subMenu) => mapSubMenuToFlattened(subMenu)) ?? []
        );
    };

    const items = getMenuItems();
    const selectedMenuItem = menuContent.find((item) => item.id === activeMenu);

    return (
        <Grid2
            container
            spacing={1}
            sx={{
                flexWrap: "nowrap",
            }}
        >
            <Grid2 sx={{ width: "350px" }}>
                <SubNavigationCategoryList
                    activeMenu={activeMenu}
                    hasMenuItems={hasMenuItems}
                    items={items}
                    setActiveMenu={(value, link: null | string) => {
                        if (typeof value === "string") {
                            handleSubMenuClick(value, link);
                        }
                    }}
                    setMenuContent={setMenuContentByKey}
                />
            </Grid2>
            {selectedMenuItem && (
                <Grid2
                    container
                    sx={{
                        marginBottom: "15px",
                        width: hasMenuItems ? "1000px" : "auto",
                    }}
                >
                    <MegaMenuColumns
                        onItemClick={onItemClick}
                        selectedMenuItem={selectedMenuItem}
                    />
                </Grid2>
            )}
        </Grid2>
    );
}

function MegaMenuColumns({
    onItemClick,
    selectedMenuItem,
}: {
    onItemClick: (link: null | string) => void;
    selectedMenuItem: FlattenedMenuItem;
}) {
    const { children = [] } = selectedMenuItem;
    const itemsWithChildren = children.filter(
        (column) => column.children && column.children.length > 0,
    );
    const childlessItems = children.filter(
        (column) => !(column.children && column.children.length > 0),
    );

    return (
        <>
            {childlessItems.length > 0 && (
                <Grid2 key={selectedMenuItem.id} size="auto">
                    <MegaMenuColumn
                        column={{
                            icon: selectedMenuItem.icon,
                            items: childlessItems,
                            title: selectedMenuItem.label,
                        }}
                        onItemClick={onItemClick}
                    />
                </Grid2>
            )}
            {itemsWithChildren.map((column) => (
                <Grid2 key={column.id} size="auto">
                    <MegaMenuColumn
                        column={{
                            icon: column.icon,
                            items: column.children,
                            title: column.label,
                        }}
                        onItemClick={onItemClick}
                    />
                </Grid2>
            ))}
        </>
    );
}
