import { commonAPI } from "@/api";
import { OverviewDataGrid } from "@/components/datagrid/overview-datagrid";
import { ErrorState, PendingState } from "@/components/general/state";
import { usePagedCommonQueryParameters } from "@/hooks/queries/use-paged-common-query-parameters";
import { components } from "commonapi.openapi";

import { ContactOverviewColumns } from "./columns/contact";

export type RepositoryType = components["schemas"]["ContactResponse"];

export default function ContactOverviewProvider() {
    const rowID: keyof RepositoryType = "contact_id";
    const provider = "contact";
    const title = "Contact";
    const columns = ContactOverviewColumns();

    const { pagedCommonQueryParameters, paginationModel, setPaginationModel } =
        usePagedCommonQueryParameters();

    const { data, error, isError, isPending } = commonAPI.useQuery(
        "get",
        "/common-api/v1/{cash_customer}/{company_id}/contacts",
        pagedCommonQueryParameters,
    );

    const datagridProps = {
        paginationModel: paginationModel,
        provider: provider,
        rowID: rowID,
        setPaginationModel: setPaginationModel,
        title: title,
    };

    if (isPending) {
        return <PendingState />;
    }

    if (isError) {
        return <ErrorState error={error} />;
    }

    return (
        <OverviewDataGrid columns={columns} data={data} {...datagridProps} />
    );
}
