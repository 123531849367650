import { globalAPI } from "@/api";
import { IconName } from "@/theme";
import { Paper, Popper } from "@mui/material";
import { components } from "globalapi.openapi";
import { useEffect, useRef, useState } from "react";

import { ErrorChip, LoadingChip } from "../general/chips";
import MenuContent from "./menu-content";
import TopMenuButtons from "./top-menu-buttons";

export type FlattenedMenuItem = {
    children: FlattenedMenuItem[];
    i18n_tag: string;
    icon?: IconName;
    id: string;
    label: string;
    link: null | string;
};

export function SubNavigation() {
    const {
        data: menu,
        error,
        isError,
        isPending,
    } = globalAPI.useQuery("get", "/global-api/v1/menu_items_organized");

    const [menuKey, setMenuKey] = useState<null | string>(null);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(
        null,
    );
    const open = Boolean(anchorElement);
    const [activeMenu, setActiveMenu] = useState<null | string>(null);
    const [menuContent, setMenuContent] = useState<FlattenedMenuItem[]>([]);
    const menuRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target as Node) &&
                anchorElement &&
                !anchorElement.contains(event.target as Node)
            ) {
                handleClose();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, anchorElement]);

    const setMenuContentByKey = (id: string) => {
        if (menu) {
            const menuItem = menu.menuItems
                ? findMenuItemById(menu.menuItems, id)
                : undefined;
            if (menuItem) {
                const flattenedItems = flattenMenuItems([menuItem]);
                setMenuContent(flattenedItems);
            } else {
                setMenuContent([]);
            }
        }
    };

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        id: string,
    ) => {
        if (menuKey === id) {
            handleClose();
            return;
        }

        const menuCategory = findMenuCategoryById(id);

        if (!menuCategoryHasChildren(menuCategory)) {
            return;
        }

        setMenuKey(id);
        setMenuContentByKey(id);

        if (menuCategory) {
            const flattenedItems = flattenMenuItems(
                menuCategory.menuItems ?? [],
            );
            setMenuContent(flattenedItems);
            if (flattenedItems.length > 0) {
                setActiveMenu(flattenedItems[0].id);
            }
        } else {
            setMenuContent([]);
        }

        setAnchorElement(event.currentTarget);
    };

    const findMenuCategoryById = (id: string) => {
        return menu?.menuItems?.find(
            (item) => item.menuItem?.menu_item_id === id,
        );
    };

    const handleSubMenuClick = (id: string, link: null | string) => {
        setActiveMenu(id);
        setMenuContentByKey(id);
        if (link) {
            handleClose();
        }
    };

    const handleClose = () => {
        setAnchorElement(null);
        setActiveMenu(null);
        setMenuKey(null);
        setMenuContent([]);
    };

    const hasMenuItems = menuContent.some(
        (item) => item.children && item.children.length > 0,
    );

    const handleItemClick = (link: null | string) => {
        if (link && link !== "") {
            handleClose();
        }
    };

    if (isPending) {
        return <LoadingChip />;
    }

    if (isError) {
        return <ErrorChip error={error} />;
    }

    return (
        <>
            <Paper elevation={3} square sx={{ flexGrow: 1, height: "48px" }}>
                <TopMenuButtons
                    handleClick={handleClick}
                    menu={flattenMenuItems(menu?.menuItems ?? [])}
                    menuKey={menuKey}
                />
            </Paper>
            <Popper
                anchorEl={anchorElement}
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [0, 10],
                        },
                    },
                ]}
                open={open}
                placement="bottom-start"
            >
                <Paper ref={menuRef}>
                    <MenuContent
                        activeMenu={activeMenu}
                        handleSubMenuClick={handleSubMenuClick}
                        hasMenuItems={hasMenuItems}
                        menu={menu}
                        menuContent={menuContent}
                        menuKey={menuKey}
                        onItemClick={handleItemClick}
                        setMenuContentByKey={setMenuContentByKey}
                    />
                </Paper>
            </Popper>
        </>
    );
}

function findMenuItemById(
    items: components["schemas"]["MenuStructureResponse"][],
    id: string,
): components["schemas"]["MenuStructureResponse"] | undefined {
    for (const item of items) {
        if (item.menuItem?.menu_item_id === id) {
            return item;
        }
        if (item.menuItems && item.menuItems.length > 0) {
            const found = findMenuItemById(item.menuItems, id);
            if (found) {
                return found;
            }
        }
    }
    return undefined;
}

function flattenMenuItems(
    items: components["schemas"]["MenuStructureResponse"][],
): FlattenedMenuItem[] {
    const result: FlattenedMenuItem[] = [];

    function createNewItem(
        item: components["schemas"]["MenuStructureResponse"],
    ): FlattenedMenuItem {
        return {
            children: [],
            i18n_tag: getI18nTag(item),
            icon: getIcon(item),
            id: getId(item),
            label: getLabel(item),
            link: getLink(item),
        };
    }

    function getI18nTag(
        item: components["schemas"]["MenuStructureResponse"],
    ): string {
        return item.menuItem?.i18n_tag || "";
    }

    function getIcon(
        item: components["schemas"]["MenuStructureResponse"],
    ): IconName | undefined {
        return item.menuItem?.icon as IconName | undefined;
    }

    function getId(
        item: components["schemas"]["MenuStructureResponse"],
    ): string {
        return item.menuItem?.menu_item_id || "";
    }

    function getLabel(
        item: components["schemas"]["MenuStructureResponse"],
    ): string {
        return item.menuItem?.label || "";
    }

    function getLink(
        item: components["schemas"]["MenuStructureResponse"],
    ): string {
        return item.menuItem?.link || "";
    }

    function addChildren(
        items: components["schemas"]["MenuStructureResponse"][],
        parent: FlattenedMenuItem | null,
    ) {
        for (const item of items) {
            const newItem = createNewItem(item);

            if (parent) {
                parent.children.push(newItem);
            } else {
                result.push(newItem);
            }

            if (item.menuItems && item.menuItems.length > 0) {
                addChildren(item.menuItems, newItem);
            }
        }
    }

    addChildren(items, null);
    return result;
}

function menuCategoryHasChildren(
    menuCategory?: components["schemas"]["MenuStructureResponse"],
) {
    return (
        menuCategory &&
        menuCategory.menuItems &&
        menuCategory.menuItems.length > 0
    );
}
