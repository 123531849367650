import type { RJSFSchema } from "@rjsf/utils";

export const contactFormSchema: RJSFSchema = {
    properties: {
        contact_id: {
            title: "Contact ID",
            type: "string",
        },
        email: {
            title: "Email",
            type: "string",
        },
        first_name: {
            title: "First Name",
            type: "string",
        },
        last_name: {
            title: "Last Name",
            type: "string",
        },
        phone: {
            title: "Phone",
            type: "string",
        },
    },
    required: ["contact_id", "first_name", "last_name"],
    type: "object",
};
