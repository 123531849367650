import type { GridPaginationModel } from "@mui/x-data-grid";

import { useState } from "react";

/**
 * A hook that provides a state tuple containing the current page number and the number of rows in each page.
 * @returns A state tuple containing the current page number and the number of rows in each page.
 */
export function usePaginationModel() {
    return useState<GridPaginationModel>({
        /**
         * The current page number.
         * @default 0
         * @type number
         */
        page: 0,
        /**
         * The number of rows in each page.
         * @default 10
         * @type number
         */
        pageSize: 10,
    });
}
