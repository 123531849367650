import type { AuthProviderProps } from "react-oidc-context";

import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/ubuntu/300.css";
import "@fontsource/ubuntu/400.css";
import "@fontsource/ubuntu/500.css";
import "@fontsource/ubuntu/700.css";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import theme from "@theme/index";
import { WebStorageStateStore } from "oidc-client-ts";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oidc-context";

import DefaultError from "./components/general/default-error";
import DefaultNotFound from "./components/general/default-not-found";
import "./i18n";
import rootRoute from "./routes/__root";
import balanceRoute from "./routes/balance";
import companyRoute from "./routes/company";
import companyOverviewRoute from "./routes/company-overview";
import editRoute from "./routes/edit";
import indexRoute from "./routes/index";
import journalOverviewRoute from "./routes/journal-overview";
import { cardLayoutRoute, heroLayoutRoute } from "./routes/layouts";
import loginRoute from "./routes/login";
import overviewRoute from "./routes/overview";
import userOverviewRoute from "./routes/user-overview";

declare module "@tanstack/react-router" {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Register {
        router: typeof router;
    }
}
const routeTree = rootRoute.addChildren([
    heroLayoutRoute.addChildren([indexRoute]),
    cardLayoutRoute.addChildren([
        balanceRoute,
        editRoute,
        overviewRoute,
        journalOverviewRoute,
        userOverviewRoute,
    ]),
    loginRoute,
    companyRoute,
    companyOverviewRoute,
]);

const router = createRouter({
    defaultErrorComponent: (error) => <DefaultError error={error} />,
    defaultNotFoundComponent: (notFound) => {
        <DefaultNotFound notFound={notFound} />;
    },
    routeTree,
    scrollRestoration: true,
});

const queryClient = new QueryClient();

const oidcConfig: AuthProviderProps = {
    authority: import.meta.env.VITE_OAUTH_AUTHORITY,
    client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
    onRemoveUser: async () => {
        await router.navigate({ to: "/login" });
    },
    onSigninCallback: (_user) => {
        globalThis.history.replaceState(
            {},
            document.title,
            globalThis.location.pathname,
        );
    },
    redirect_uri: import.meta.env.VITE_OAUTH_REDIRECT_URL,
    response_mode: "query",
    response_type: "code",
    scope: "openid profile email",
    userStore: new WebStorageStateStore({ store: globalThis.localStorage }),
};

const rootElement = document.querySelector("#app")!;
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <StrictMode>
            <AuthProvider {...oidcConfig}>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={createTheme(theme)}>
                        <CssBaseline />
                        <RouterProvider router={router} />
                    </ThemeProvider>
                </QueryClientProvider>
            </AuthProvider>
        </StrictMode>,
    );
}
